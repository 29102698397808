import { TooltipFormatterContextObject } from 'highcharts';
import './tooltips.css';
import { format } from 'date-fns';
import { TFunction } from 'i18next';
import { pathOr } from 'ramda';
import { upperCaseFirstLetter } from '../text';
import { formatDecimalNumber, formatWholeNumber } from 'utils/formatting/numbers';
import { TRANSACTIONS_REVENUE } from 'utils/common/constants';

const tooltipHeaderFormater = (
    point: TooltipFormatterContextObject,
    yoyLineChart: boolean | undefined,
    timeRangeChart?: boolean
) => {
    if (yoyLineChart) {
        const year = Number(point.series.name);
        return format(new Date(new Date(point.x).setFullYear(year)), 'MMMM do');
    } else if (typeof point.x === 'string') {
        return point.x;
    } else if (!timeRangeChart && timeRangeChart !== undefined) {
        return point.key;
    } else if (typeof point.key === 'string') {
        return point.key;
    } else {
        if (point.x === 0) {
            return '';
        }
        return format(new Date(point.x), 'MMMM do yyyy, H:mm a');
    }
};

export const tooltipFormatter =
    (t: any, headerFormatter?: any) =>
    (
        tooltipOptions: TooltipFormatterContextObject,
        tooltipLabel?: string,
        metric?: string,
        metricTooltip?: boolean,
        timeRangeChart?: boolean,
        yoyLineChart?: boolean,
        labelFormatter?: (label: string) => string,
        tooltipDecimals?: number
    ): string => {
        //if there is points property it means that the tooltips are shared
        const shared = !!tooltipOptions.points;
        const points: TooltipFormatterContextObject[] = shared
            ? tooltipOptions.points || []
            : [tooltipOptions] || [];
        const series = points[0].series;
        const data: any = shared ? points : series.data;
        const totalValue: number = data[0]?.custom?.totals
            ? data[0].custom.totals
            : data.reduce((a: number, datapoint: any) => {
                  const yValue = pathOr(datapoint.y, ['point', 'yDisplayValue'], datapoint);
                  return (
                      a +
                      (datapoint && datapoint?.point?.isNegative ? (yValue || 0) * -1 : yValue || 0)
                  );
              }, 0);
        const label = headerFormatter
            ? headerFormatter(points[0], yoyLineChart, timeRangeChart)
            : tooltipHeaderFormater(points[0], yoyLineChart, timeRangeChart);

        const metricSymbol = () => {
            if (metric === TRANSACTIONS_REVENUE) {
                return t('amount');
            } else {
                return '#';
            }
        };

        if (shared) {
            return `<b>${label}</b>
                <table class="tooltip_table">${tooltipRow([
                    tooltipLabel || '',
                    '%',
                    metricSymbol()
                ]).concat(
                    points
                        .map((point: TooltipFormatterContextObject) =>
                            formattingSharedTooltipRow(
                                point,
                                totalValue,
                                t,
                                metric,
                                labelFormatter,
                                tooltipDecimals,
                                metricTooltip
                            )
                        )
                        .join('')
                )}`.concat(
                `${tooltipRow([
                    `<b>${t('total')}</b>`,
                    `<b>100%</b>`,
                    `<b>${formatWholeNumber(totalValue)}</b>`
                ])}
                    </table>`
            );
        } else {
            return `<table class="tooltip_table">${tooltipRow([String(label), ''])}<br>`.concat(
                `${formattingTooltipRow(t)(t(metric), points[0], totalValue)}
                </table>`
            );
        }
    };

const formattingSharedTooltipRow = (
    point: TooltipFormatterContextObject & ASHighchartsPoint,
    totalValue: number,
    t: TFunction,
    metric?: string,
    labelFormatter?: (label: string) => string,
    decimalNumber?: number,
    metricTooltip?: boolean
) => {
    const yValue = pathOr(point.y, ['point', 'yDisplayValue'], point);
    const pointName = point.series.name ? point.series.name : t('notSet');

    const pointLabel = `<div><span style="color:${point.color}; font-size: 15px;">&#9632;</span> ${
        labelFormatter
            ? labelFormatter(pointName)
            : metricTooltip && metric
              ? t(metric)
              : upperCaseFirstLetter(pointName)
    }</div`;
    const pointValue = formatWholeNumber(yValue);
    const pointTotal = totalValue;
    const pointPercentageValue = formatDecimalNumber(
        (yValue / pointTotal) * 100,
        decimalNumber,
        decimalNumber
    );
    const pointPercentage = !isNaN(Number(pointPercentageValue))
        ? `${pointPercentageValue} %`
        : t('N/A');
    return tooltipRow([pointLabel, pointPercentage, pointValue]);
};

interface ASHighchartsPoint {
    point: {
        yDisplayValue?: number;
    };
}

const formattingTooltipRow =
    (t: any) =>
    (
        metric: string,
        point: TooltipFormatterContextObject & ASHighchartsPoint,
        totalValue: number
    ) => {
        const yValue = pathOr(point.y, ['point', 'yDisplayValue'], point);
        const pointValue = formatWholeNumber(yValue);
        const pointPercentageValue = formatDecimalNumber((yValue / totalValue) * 100);
        const pointPercentage = !isNaN(Number(pointPercentageValue))
            ? `${pointPercentageValue} %`
            : t('N/A');
        return tooltipRow([metric, pointValue]).concat(
            tooltipRow([t('percentage'), pointPercentage])
        );
    };

const tooltipRow = (cells: string[]) => `<tr class="tooltip_table_row">
	${cells.map(formatTooltipCell).join('')}
</tr>`;

const formatTooltipCell = (value: string | number) =>
    `<td class="tooltip_able_row_cell">${value}</td>`;
