import React, { MutableRefObject } from 'react';
import Highcharts, { TooltipFormatterContextObject } from 'highcharts';
import HighchartsCustomEvents from 'highcharts-custom-events';
import exporting from 'highcharts/modules/exporting';
import csvExporting from 'highcharts/modules/export-data';
import HighchartsReact from 'highcharts-react-official';
//@ts-ignore
HighchartsCustomEvents(Highcharts);
exporting(Highcharts);
csvExporting(Highcharts);
import { styled, useTheme } from '@mui/material/styles';
import { mergeDeepLeft, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';

import { DateFormatContext } from '../common/datepicker';
import { ColumnChartProps } from './types';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

import { formatWholeNumber } from 'utils/formatting/numbers';

import { getTooltipTopPositionAdjustment } from 'utils/chartUtils';

const groupOthers = (
    series: Highcharts.SeriesColumnOptions[],
    name: string
): Highcharts.SeriesColumnOptions =>
    ({
        name,
        color: series[0].color,
        data: Object.entries(
            series.reduce(
                (p: { [key: string]: { y: number } }, v: Highcharts.SeriesColumnOptions) => {
                    ((v.data || []) as Array<{ x: number; y: number }>).forEach(({ x, y }) => {
                        p[String(x)] = p[String(x)] || { y: 0 };
                        p[String(x)].y += y;
                    });
                    return p;
                },
                {} as { [key: string]: { y: number } }
            )
        ).map(([x, { y }]) => ({ x: Number(x), y }))
    }) as Highcharts.SeriesColumnOptions;

const ColumnChart = ({
    series,
    categories,
    columnOptions = {},
    onColumnClick,
    metric,
    exportFileName,
    timeRangeChart,
    subtitleOne,
    chartCallback,
    steps,
    granularity,
    id
}: ColumnChartProps) => {
    const { t } = useTranslation();
    const chartRef: any = React.useRef(null);
    const theme = useTheme();
    const { formatChartDatetime, formatTooltipHeader } = React.useContext(DateFormatContext);
    const firstColumnNameExport = pathOr('', ['0', 'name'], series);

    const [options, setOptions] = React.useState<Highcharts.Options>({
        chart: {
            type: 'column',
            height: 272,
            spacingTop: 30,
            spacingBottom: 14,
            spacingLeft: 14,
            spacingRight: 14,
            style: {
                fontFamily: theme.fonts.primaryFont
            }
        },
        xAxis: {
            type: 'datetime',
            labels: {
                autoRotation: undefined,
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                },
                formatter: function () {
                    if (this.chart.xAxis[0].userOptions.type === 'datetime' && granularity) {
                        return formatChartDatetime(this.value, granularity);
                    }

                    return String(this.value);
                }
            }
        },
        yAxis: {
            title: {
                text: undefined
            },
            labels: {
                style: {
                    color: theme.colors.text,
                    fontSize: theme.fontSizes.small
                }
            }
        },
        credits: {
            enabled: false
        },
        title: {
            useHTML: true,
            text: `<div style="position: absolute; top: -20px; display: flex; justify-content: space-between; width: 230px;"><span style="max-width: 160px;">${
                subtitleOne || ''
            }</span></div>`,
            align: 'left',
            x: 25,
            y: 9,
            style: {
                fontSize: theme.fontSizes.normal,
                color: theme.colors.text,
                fontWeight: 'bold'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                groupPadding: 0,
                pointPadding: 0.1,
                borderWidth: 0,
                maxPointWidth: 21,
                dataLabels: {
                    enabled: false
                },
                point: {
                    events: {
                        click(ev) {
                            onColumnClick && onColumnClick.current(ev);
                        }
                    }
                }
            },
            series: {
                cursor: onColumnClick && 'pointer',
                animation: {
                    duration: 0
                }
            }
        },

        exporting: {
            enabled: false,
            filename: exportFileName
        },
        series:
            (series || []).length > 15
                ? series
                      ?.slice(0, 14)
                      .concat([
                          groupOthers(
                              series.slice(14) as Highcharts.SeriesColumnOptions[],
                              `${t('otherRowCount')}: ${formatWholeNumber(series.length - 14)}`
                          )
                      ])
                : series
    });

    React.useEffect(() => {
        const newSeries: Highcharts.Options = {
            series:
                (series || []).length > 15
                    ? series
                          ?.slice(0, 14)
                          .concat([
                              groupOthers(
                                  series.slice(14) as Highcharts.SeriesColumnOptions[],
                                  `${t('otherRowCount')}: ${formatWholeNumber(series.length - 14)}`
                              )
                          ])
                    : series,
            xAxis: {
                categories,
                labels: {
                    //@ts-ignore
                    events: {
                        click: function (this: any) {
                            const point = {
                                point: this.chart.series[0].points[this.pos]
                            };
                            onColumnClick &&
                                onColumnClick.current(point as Highcharts.SeriesClickEventObject);
                        }
                    },
                    style: {
                        cursor: onColumnClick && 'pointer'
                    },
                    step: steps ? steps : 0
                }
            },
            title: {
                useHTML: true,
                text: `<div style="position: absolute; top: -20px;display: flex; justify-content: space-between; width: 230px;"><span style="max-width: 160px;">${
                    subtitleOne || ''
                }</span></div>`,
                align: 'left',
                x: 25,
                y: 9,
                style: {
                    fontSize: theme.fontSizes.normal,
                    color: theme.colors.text,
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                shared: true,
                useHTML: true,
                outside: true,
                positioner(
                    this: Highcharts.Tooltip,
                    labelWidth,
                    labelHeight,
                    point: Highcharts.Point | Highcharts.TooltipPositionerPointObject
                ) {
                    const chartPosition = this.chart.pointer.getChartPosition();

                    if ('plotX' in point) {
                        // Lower tooltip position if close to top of screen:
                        const adjustment = getTooltipTopPositionAdjustment(
                            this.chart.container,
                            labelHeight
                        );

                        return {
                            x: chartPosition.left + point.plotX - labelWidth / 3,
                            y: chartPosition.top - labelHeight + adjustment
                        };
                    }
                    return {
                        x: chartPosition.left - labelWidth / 3,
                        y: chartPosition.top - labelHeight
                    };
                },
                formatter(this: TooltipFormatterContextObject) {
                    return tooltipFormatter(t, formatTooltipHeader)(
                        this,
                        '',
                        metric,
                        undefined,
                        timeRangeChart
                    );
                }
            },
            plotOptions: {
                column: {
                    events: {
                        click(this: Highcharts.Series, ev: Highcharts.SeriesClickEventObject) {
                            onColumnClick?.current.apply(this, [ev]);
                        }
                    }
                }
            },
            exporting: {
                csv: {
                    columnHeaderFormatter: function (
                        item: Highcharts.SeriesBarOptions[] | Highcharts.Options['series'],
                        key: 'x' | 'y'
                    ) {
                        console.log(item);
                        if (!item || item instanceof Highcharts.Axis) {
                            return firstColumnNameExport;
                        }
                        return { columnTitle: key === 'y' ? t(metric as string) : key };
                    },
                    dateFormat: '%Y-%m-%d'
                }
            }
        };
        mergePassedOptions(mergeDeepLeft(columnOptions, newSeries) as Highcharts.Options);
    }, [categories, series, onColumnClick]);

    React.useEffect(() => {
        const newOptions: Highcharts.Options = {
            xAxis: {
                labels: {
                    formatter: function () {
                        if (this.chart.xAxis[0].userOptions.type === 'datetime' && granularity) {
                            return formatChartDatetime(this.value, granularity);
                        }
                        return String(this.value);
                        /*const strValue = String(this.value);
            return strValue.length === 0 ? strValue : strValue.substring(0, 1).toLocaleUpperCase();*/
                    }
                }
            }
        };

        mergePassedOptions(newOptions);
    }, [granularity]);

    const mergePassedOptions = (extraOptions: Highcharts.Options) => {
        setOptions((options) => mergeDeepLeft(extraOptions, options) as Highcharts.Options);
    };

    const handleChartCallback = React.useRef(() => {
        chartCallback && chartCallback(chartRef as MutableRefObject<{ chart: Highcharts.Chart }>);
    });

    return (
        <Wrapper id={id}>
            <HighchartsReact
                ref={chartRef}
                highcharts={Highcharts}
                options={options}
                callback={handleChartCallback.current}
            />
        </Wrapper>
    );
};

const Wrapper = styled('div')`
    min-width: 0;
`;

export { ColumnChart };
