import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './utils/localization';

Sentry.init({
    dsn: 'https://46e6ec5cc77ab69036c40c5846623485@o196978.ingest.us.sentry.io/4507855598583808',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    enabled: process.env.NODE_ENV === 'production',
    // Tracing
    tracesSampleRate: 0.01, //  Capture 100% of the transactions
    replaysSessionSampleRate: 0.005,
    replaysOnErrorSampleRate: 1.0
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
