import { isEqual, format, differenceInCalendarDays } from 'date-fns';
import { TimeDimensionGranularity } from '@cubejs-client/core';
import { DateRange, date } from './types';

export const dateFormat = 'yyyy/MM/dd';

export const eventDateTime = 'yyyy-MM-dd hh:ss';

const queryDateFormat = `yyyy-MM-dd'T'HH:mm:ss`;

const shortAccurateDateFormat = `yyyyMMdd-HHmmss`;

export const formatCubeQueryDate = (date: Date) => format(date, queryDateFormat);

export const checkIfFirstWeekOfYear = (date: Date) =>
    date.getTime() < new Date(date.getFullYear(), 11, 31).getTime();

export const isEqualDateRange = (A: DateRange, B: DateRange): boolean =>
    isEqual(A[0], B[0]) && isEqual(A[1], B[1]);

export const isDate = (value: any): value is date => value instanceof Date;

export const isStringDate = (value: string) => !isNaN(Date.parse(value));

export const isDateRange = (value: any): value is DateRange =>
    Array.isArray(value) && value.length === 2 && isDate(value[0]) && isDate(value[1]);

export const isDateValid = (date: Date | number) =>
    +date &&
    !isNaN(+date) &&
    (!(date instanceof Date) ||
        ((date as Date).getTime() > 0 && (date as Date).getUTCFullYear() !== 1970));

export const formatEventBeginsDateTime = (date: Date | string) =>
    format(isDate(date) ? date : new Date(date), eventDateTime);

export const daysOut = (date: Date | string) => {
    const nowDate = new Date();
    const givenDate = new Date(date);
    return differenceInCalendarDays(givenDate, nowDate);
};

export const formatDate = (date: Date | string) =>
    format(isDate(date) ? date : new Date(date), dateFormat);

export const formatshortAccurateDate = (date: Date | string) =>
    format(isDate(date) ? date : new Date(date), shortAccurateDateFormat);

export const formatDateRange = (dateRange: DateRange) =>
    `${formatDate(dateRange[0])} - ${formatDate(dateRange[1])}`;

const zeroPad = (str: number) => {
    return str.toString().padStart(2, '0');
};

// Returns a format like this: 2023-03-15 19:30:00
export const getUtcString = (date: Date): string => {
    const month = zeroPad(date.getUTCMonth() + 1);
    const day = zeroPad(date.getUTCDate());
    const hours = zeroPad(date.getUTCHours());
    const minutes = zeroPad(date.getUTCMinutes());
    const seconds = zeroPad(date.getUTCSeconds());

    return `${date.getUTCFullYear()}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

export const subtractTimezone = (date: Date) => {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
};

// TODO: needs to be configurable
export const currentDate = () => new Date();

// Converts a date value in utc integer form to a string, using the given timezone and format:
const convertUTCToTimezone = (utcInteger: number, timezone: string, format: string): string => {
    // Create a Date object from the UTC integer
    const date = new Date(utcInteger);

    // Define options to extract the components of the date
    const options: Intl.DateTimeFormatOptions = { timeZone: timezone, hour12: false };

    // Define the mappings from format tokens to Intl.DateTimeFormatOptions keys
    const tokenOptions: { [key: string]: Intl.DateTimeFormatOptions } = {
        HH: { hour: '2-digit' },
        mm: { minute: '2-digit' },
        MMM: { month: 'short' },
        MM: { month: '2-digit' },
        d: { day: 'numeric' },
        dd: { day: '2-digit' },
        yy: { year: '2-digit' },
        yyyy: { year: 'numeric' }
    };

    // Define a map to store the formatted components
    const formattedComponents: { [key: string]: string } = {};

    // Extract the components of the date based on the token options
    Object.keys(tokenOptions).forEach((token) => {
        const tokenOption = tokenOptions[token];
        const formatter = new Intl.DateTimeFormat('en-US', { ...options, ...tokenOption });
        formattedComponents[token] =
            token !== 'mm' ? formatter.format(date) : formatter.format(date).padStart(2, '0');
    });

    // Build the final formatted date by replacing tokens in the format string
    const formattedDate = format.replace(/HH|MMM|MM|mm|dd|d|yyyy|yy/g, (match) => {
        return formattedComponents[match];
    });

    return formattedDate;
};

// Converts a date value in utc integer form to a string, using the given timezone and granularity:
export const dateUTCIntToDateStrGran = (
    utcInteger: number,
    timezone: string,
    granularity: TimeDimensionGranularity
): string => {
    if (granularity) {
        switch (granularity) {
            case 'hour': {
                return convertUTCToTimezone(utcInteger, timezone, 'HH:mm');
            }
            case 'day': {
                return convertUTCToTimezone(utcInteger, timezone, 'd. MMM');
            }
            case 'week': {
                return convertUTCToTimezone(utcInteger, timezone, 'd. MMM.');
            }
            case 'month': {
                return convertUTCToTimezone(utcInteger, timezone, "MMM 'yy");
            }
            case 'year': {
                return convertUTCToTimezone(utcInteger, timezone, 'yyyy');
            }
            default: {
                return '';
            }
        }
    }

    return convertUTCToTimezone(utcInteger, timezone, 'd MMM HH:mm yyyy');
};

// Converts a date value in utc integer form to a string, using the given timezone and date-fns format:
export const dateUTCIntToDateStrFormat = (
    utcInteger: number,
    timezone: string,
    format: string
): string => {
    return convertUTCToTimezone(utcInteger, timezone, format);
};

export const getMidnightUTCTime = (date: Date) => {
    // Extract year, month, and date
    const year = date.getFullYear();
    const month = date.getMonth(); // Note: months are zero-indexed (0 = January, 11 = December)
    const day = date.getDate();

    // Create a new Date object set to midnight UTC of the same date
    const midnightDate = Date.UTC(year, month, day);

    return midnightDate;
};
