import { lazy } from 'react';
import { Route } from './routingTypes';

export interface AllRoutes {
    [key: string]: Route<any>[];
}

export const allRoutes: AllRoutes = {
    'as-LE-Sports-standard': [
        {
            path: '/sales_overview',
            label: 'salesOverview',
            internalName: 'Sales Overview',
            index: 0,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/SalesOverview/SalesOverview'))
        },
        {
            path: '/activity-stream/EntityOverview/:entityType/:entityRef',
            component: lazy(
                () => import('views/dashboards/EntityOverviewHandler/EntityOverviewHandler')
            )
        },
        {
            path: '/customer_overview',
            label: 'customerOverview',
            internalName: 'Customers Overview',
            index: 1,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/CustomerOverview/CustomerOverview'))
        },
        {
            path: '/product_sales_overview',
            label: 'productSalesOverview',
            internalName: 'Product Sales Overview',
            index: 2,
            dashboardGroup: 'Dashboards',
            component: lazy(
                () => import('views/dashboards/ProductSalesOverview/ProductSalesOverview')
            )
        },
        {
            path: '/donation_overview',
            label: 'donationOverview',
            internalName: 'Donation Overview',
            index: 3,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/DonationOverview/DonationOverview'))
        },
        {
            path: '/event_status_overview',
            label: 'eventStatusOverview',
            internalName: 'Event Status Overview',
            index: 4,
            dashboardGroup: 'Dashboards',
            component: lazy(
                () => import('views/dashboards/EventStatusOverview/EventStatusOverview')
            )
        },
        {
            path: '/daily_wrap',
            label: 'dailyWrap',
            internalName: 'Daily Wrap',
            index: 5,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/DailyWrap/DailyWrap'))
        },
        {
            path: '/year_over_year',
            label: 'yearOnYear',
            internalName: 'Year Over Year',
            index: 6,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/YearOverYear/YearOverYear'))
        },
        {
            path: '/marketing_overview',
            label: 'marketingOverview',
            internalName: 'Marketing Overview',
            index: 7,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/CampaignOverview/CampaignOverview'))
        },
        {
            path: '/email_campaign_overview',
            label: 'emailCampaignOverview',
            internalName: 'Email Campaign Overview',
            index: 8,
            dashboardGroup: 'Dashboards',
            component: lazy(
                () => import('views/dashboards/EmailCampaignOverview/EmailCampaignOverview')
            )
        },
        {
            path: '/segment_builder',
            label: 'segmentBuilder',
            internalName: 'Segment Builder',
            index: 9,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/SegmentBuilder/SegmentBuilder'))
        },
        {
            path: '/segment_mixer',
            label: 'segmentMixer',
            internalName: 'Segment Mixer',
            index: 10,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/SegmentMixerDashboard/SegmentMixerDashboard')
            )
        },
        {
            path: '/segment_mixer_saved',
            label: 'segmentMixerSaved',
            internalName: 'Saved Segment Mixer Segments',
            component: lazy(
                () => import('views/dashboards/SegmentMixerDashboard/SavedSegmentMixerSegments')
            )
        },
        {
            path: '/data_quality_overview',
            label: 'dataQualityOverview',
            internalName: 'Data Quality Overview',
            requiredRole: 'ADMINISTRATOR',
            index: 11,
            dashboardGroup: 'Dashboards',
            component: lazy(
                () => import('views/dashboards/DataQualityOverview/DataQualityOverview')
            )
        },
        {
            path: '/sales_channels',
            label: 'salesChannels',
            internalName: 'Sales channels',
            index: 12,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/SalesChannels/SalesChannels'))
        },
        {
            path: '/advance_sales_overview',
            label: 'advanceSalesOverview',
            internalName: 'Advance Sales Overview',
            index: 13,
            dashboardGroup: 'Dashboards',
            component: lazy(
                () => import('views/dashboards/AdvanceSalesOverview/AdvanceSalesOverview')
            )
        },
        {
            path: '/shubert_realtime_overview',
            label: 'realTimeOverview',
            internalName: 'Real Time Overview',
            index: 14,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/RealTimeOverview/RealTimeOverview'))
        },
        {
            path: '/shubert_realtime_overview_mock',
            label: 'realTimeOverviewMock',
            internalName: 'Real Time Overview',
            index: 15,
            dashboardGroup: 'Dashboards',
            component: lazy(() => import('views/dashboards/RealTimeOverview/RealTimeOverview'))
        },
        {
            path: '/meta_campaign/list',
            label: 'metaCampaign', // Requires translation
            internalName: 'Meta Campaign',
            index: 16,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/MetaCampaign/MetaCampaignList/MetaCampaignList')
            )
        },
        {
            path: '/meta_campaign/create',
            label: 'metaCampaignCreation',
            internalName: 'Meta Campaign Creation',
            component: lazy(
                () =>
                    import('views/dashboards/MetaCampaign/MetaCampaignBuilder/MetaCampaignBuilder')
            )
        }
    ],
    'as-plus-services': [
        {
            path: '/Channels',
            label: 'channels',
            internalName: 'Channels',
            index: 0,
            dashboardGroup: 'Channels',
            component: lazy(() => import('views/dashboards/Channels/Channels'))
        }
    ],
    'as-core-services': [
        {
            path: '/user_manager',
            label: 'userManager',
            internalName: 'User Manager',
            requiredRole: 'MANAGER',
            index: 0,
            dashboardGroup: 'Settings',
            component: lazy(() => import('views/dashboards/UserManager/UserManager'))
        },
        {
            path: '/roles',
            label: 'customRolesManagement',
            internalName: 'Custom Roles Management',
            requiredRole: 'ADMINISTRATOR',
            index: 1,
            dashboardGroup: 'Settings',
            component: lazy(() => import('views/dashboards/RolesManagement/RolesManagement'))
        },
        {
            path: '/tenant_settings',
            label: 'tenantSettings',
            internalName: 'Tenant Settings',
            requiredRole: 'ADMINISTRATOR',
            index: 2,
            dashboardGroup: 'Settings',
            component: lazy(() => import('views/dashboards/TenantSettings/TenantSettings'))
        },
        {
            path: '/anonymize',
            label: 'anonymizer',
            internalName: 'GDPR Anonymizer',
            requiredRole: 'ADMINISTRATOR',
            index: 20,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/Tools/Anonymizer/Anonymizer'))
        }
    ],
    'as-campaign-services': [
        {
            path: '/campaign_landing',
            label: 'campaignSetup',
            internalName: 'Campaign Setup',
            index: 0,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/Tools/CampaignSetup/CampaignSetupLandingPage')
            )
        },
        {
            path: '/campaign_setup',
            label: 'campaignSetupFirstStep',
            internalName: 'Campaign Setup First Step',
            component: lazy(() => import('views/dashboards/Tools/CampaignSetup/CampaignSetup'))
        },
        {
            path: '/campaign_setup_2',
            label: 'campaignSetupSecondStep',
            internalName: 'Campaign Setup Second Step',
            component: lazy(() => import('views/dashboards/Tools/CampaignSetup/RecipientsSetup'))
        },
        {
            path: '/campaign_setup_3',
            label: 'campaignSetupThirdStep',
            internalName: 'Campaign Setup Third Step',
            component: lazy(() => import('views/dashboards/Tools/CampaignSetup/TemplateSetup'))
        },
        {
            path: '/campaign_setup_4',
            label: 'campaignSetupFourthStep',
            internalName: 'Campaign Setup Fourth Step',
            component: lazy(() => import('views/dashboards/Tools/CampaignSetup/ConfirmationSetup'))
        },
        {
            path: '/campaign_management',
            label: 'campaignManagement',
            internalName: 'Campaign Management',
            index: 1,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/Tools/CampaignManagement/CampaignManagement')
            )
        },
        {
            path: '/url_generator',
            label: 'urlGenerator',
            internalName: 'Url Generator',
            index: 2,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/Tools/UrlGenerator/UrlGenerator'))
        },
        {
            path: '/optin',
            label: 'optin',
            internalName: 'Optin List Uploader',
            index: 10,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/Tools/OptinListUploader/OptinListUploader')
            )
        },
        {
            path: '/segment_management',
            label: 'segmentManagement',
            internalName: 'Segment Management',
            index: 3,
            dashboardGroup: 'Tools',
            component: lazy(
                () => import('views/dashboards/Tools/SegmentManagement/SegmentManagement')
            )
        },
        {
            path: '/facebook_auth',
            label: 'facebookAuthorizing',
            internalName: 'Facebook Authorizer',
            index: 14,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/FacebookPermission/FacebookPermission'))
        },
        {
            path: '/optin_management',
            label: 'optinManagement',
            internalName: 'Opt-in Management',
            requiredRole: 'MANAGER',
            index: 11,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/Tools/OptInManagement/OptInManagement'))
        },
        {
            path: '/automations',
            label: 'automations',
            internalName: 'Automations',
            index: 12,
            dashboardGroup: 'Tools',
            component: lazy(() => import('views/dashboards/Tools/Automations/Automations'))
        },
        {
            path: '/campaign_type_setup',
            label: 'campaignTypeSetup',
            internalName: 'Campaign Type Setup',
            component: lazy(
                () => import('views/dashboards/Tools/CampaignTypeSetup/CampaignTypeSetup')
            )
        },
        {
            path: '/campaign_type_design_setup',
            label: 'campaignTypeDesignSetup',
            internalName: 'Campaign Type Design Setup',
            component: lazy(
                () => import('views/dashboards/Tools/CampaignTypeSetup/CampaignTypeDesignSetup')
            )
        },
        {
            path: '/event_block_designer',
            label: 'eventBlockDesigner',
            internalName: 'Event Block Designer',
            component: lazy(
                () => import('views/dashboards/Tools/EventBlockDesigner/EventBlockDesigner')
            )
        }
    ]
};
