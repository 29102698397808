import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';

import { DateFormatContext } from '../common/datepicker';
import { HighchartsBinaryRow, HighchartTooltipChart } from './types';
import { getDefaultChartConfig } from './DefaultChartConfigs';
import { formatPercent, formatWholeNumber } from 'utils/formatting/numbers';
import { tooltipFormatter } from 'utils/formatting/tooltips/tooltips';

import { lowerCaseFirstLetter } from 'utils/formatting/text';

export const BinaryRow = ({
    handleFilter,
    series,
    tooltipLabel,
    usePercentage = false,
    metric,
    minimumFractionDigits,
    cursorDefault = false,
    labelFormatter,
    tooltipDecimals
}: HighchartsBinaryRow) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const chartRef: any = React.useRef(null);
    const { formatTooltipHeader } = React.useContext(DateFormatContext);

    const [options] = React.useState<Highcharts.Options>({
        chart: {
            type: 'bar',
            height: 80,
            spacing: [0, 0, 0, 0],
            style: {
                fontFamily: theme.fonts.primaryFont
            },
            animation: {
                duration: 0
            }
        },
        title: {
            margin: 0
        },
        xAxis: {
            title: {
                text: null
            },
            labels: {
                enabled: false
            }
        },
        tooltip: {
            outside: true,
            shared: true,
            useHTML: true,
            positioner(this: Highcharts.Tooltip, labelWidth, labelHeight) {
                const chartPosition = this.chart.pointer.getChartPosition();
                const chart = this.chart as HighchartTooltipChart;
                const chartContainerWidth = chart.containerWidth / 2;
                return {
                    x: chartPosition.left + chartContainerWidth - labelWidth / 2,
                    y: chartPosition.top - labelHeight
                };
            },
            formatter(this: Highcharts.TooltipFormatterContextObject) {
                return tooltipFormatter(t, formatTooltipHeader)(
                    this,
                    t(tooltipLabel),
                    metric,
                    false,
                    undefined,
                    false,
                    (value: string) => (labelFormatter && labelFormatter(value, t)) || value,
                    tooltipDecimals
                );
            }
        },
        yAxis: {
            title: { text: null },
            labels: {
                enabled: false
            }
        },
        legend: {
            align: 'left',
            enabled: true,
            itemDistance: 5,
            margin: 0,
            y: -8,
            padding: 2,
            symbolRadius: 0,
            reversed: true,
            labelFormatter(this: Highcharts.Point | Highcharts.Series) {
                if (labelFormatter) {
                    return labelFormatter(this.name, t);
                }
                return t(lowerCaseFirstLetter(this.name));
            },
            itemStyle: {
                fontWeight: 'normal'
            }
        },

        plotOptions: {
            bar: {
                stacking: 'percent',
                point: {
                    events: {
                        click(ev) {
                            handleFilter && handleFilter(ev);
                        }
                    }
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        textOutline: 'none',
                        color: theme.colors.text,
                        fontSize: theme.fontSizes.small
                    },
                    formatter(this: Highcharts.PointLabelObject) {
                        if (usePercentage) {
                            return formatPercent(this.percentage, t, minimumFractionDigits);
                        }
                        return formatWholeNumber(this.y ?? 0);
                    }
                }
            },
            series: {
                cursor: cursorDefault ? 'default' : 'pointer',
                animation: {
                    duration: 0
                }
            }
        },
        series: series
    });

    React.useEffect(() => {
        if (chartRef.current && series) {
            const chartSeriesLength = chartRef.current.chart.series.length;
            if (chartSeriesLength > 0) {
                for (let i = 0; i < chartSeriesLength; i++) {
                    chartRef.current.chart.series[0].remove();
                }
            }
            series.map((serie) => chartRef.current.chart.addSeries(serie));
        }
    }, [series]);

    Highcharts.setOptions(getDefaultChartConfig());

    return (
        <HighchartsReact
            ref={chartRef}
            highcharts={Highcharts}
            options={options}
        />
    );
};
