import { clone, eqProps, mergeDeepRight } from 'ramda';
import {
    Campaign,
    CampaignType,
    RecipientTest,
    Template,
    TemplateHeader,
    TemplateVariable
} from './types';
import {
    AI_SUMMARIZE_API,
    CAMPAIGN_EMAIL,
    CAMPAIGN_EMAIL_REPLICATE,
    CAMPAIGN_EMAIL_TEST,
    CAMPAIGN_EMAIL_TYPES,
    GET_SEGMENT,
    SPARKPOST_LIST_IP_POOLS
} from 'utils/api/urls';
import { ASRequest } from 'utils/api/request';
import { Feedback } from './feedback';
import { ConfirmationProps } from './ConfirmationSetup';
import { addMinutes, isAfter, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { ASCubeFilter } from 'utils/common/types';
import { QueryParams } from 'views/dashboards/SegmentBuilder/types';

export const MINIMAL_FULL_TYPE: CampaignType = {
    name: '',
    tenant: '',
    channel: '',
    isDefault: false,
    defaultTemplate: {
        templateBody: '',
        templateHeader: { from: '', from_name: '', reply: '', subject: '' },
        variables: []
    }
};

export const MINIMAL_FULL_CAMPAIGN: Campaign = {
    name: '',
    tenant: '',
    template: {
        templateBody: '',
        templateHeader: { from: '', from_name: '', reply: '', subject: '' },
        variables: []
    },
    segments: null,
    type: '',
    relations: []
};

export const predefinedVariables: TemplateVariable[] = [
    { name: 'First name', value: '{{first_name}}' },
    { name: 'Last name', value: '{{last_name}}' }
];

export const predefinedSegmentVariables: TemplateVariable[] = [
    { name: 'firstName', value: '{{firstName}}' },
    { name: 'lastName', value: '{{lastName}}' },
    { name: 'gender', value: '{{gender}}' },
    { name: 'country', value: '{{country}}' },
    { name: 'countryCode', value: '{{countryCode}}' },
    { name: 'address', value: '{{address}}' },
    { name: 'state', value: '{{state}}' },
    { name: 'phoneMobile', value: '{{phoneMobile}}' }
];

export const getAllTypes = async () =>
    await ASRequest.get<CampaignType[]>(CAMPAIGN_EMAIL_TYPES).then((res) =>
        res.data.map((type) => mergeDeepRight(MINIMAL_FULL_TYPE, type) as CampaignType)
    );

export const getType = async (name: string) =>
    await ASRequest.get<CampaignType>(`${CAMPAIGN_EMAIL_TYPES}/${name}`).then(
        (res) => mergeDeepRight(MINIMAL_FULL_TYPE, res.data) as CampaignType
    );

export const createNewType = async (type: CampaignType) =>
    await ASRequest.post(CAMPAIGN_EMAIL_TYPES, type);

export const updateOnlyMetaOnType = async (type: CampaignType) =>
    await ASRequest.put(`${CAMPAIGN_EMAIL_TYPES}/${type.name}`, {
        name: type.name,
        mergeTagType: type.mergeTagType,
        defaultTemplate: { templateHeader: type.defaultTemplate.templateHeader }
    });

export const updateOnlyTemplateOnType = async (type: string, template: Template) =>
    await ASRequest.put(`${CAMPAIGN_EMAIL_TYPES}/${type}`, {
        name: type,
        defaultTemplate: template
    });

export const setDefaultType = async (type: CampaignType) =>
    await ASRequest.put(`${CAMPAIGN_EMAIL_TYPES}/${type.name}/default`);

export const getCampaignsByPageAndStatus = async (
    page: number,
    size: number,
    orderDirection: string,
    status: string,
    sortStatus: string
) => {
    if (status !== 'all') {
        return await ASRequest.get(
            `${CAMPAIGN_EMAIL}?size=${size}&page=${page}&sortingStatus=${status}&sort=${sortStatus},${orderDirection}`
        );
    }
    return await ASRequest.get(
        `${CAMPAIGN_EMAIL}?size=${size}&page=${page}&sort=${sortStatus},${orderDirection}`
    );
};

export const getCampaignsCount = async () => await ASRequest.get(`${CAMPAIGN_EMAIL}/status/count`);

export const createCampaign = async (campaign: Campaign) =>
    await ASRequest.post(CAMPAIGN_EMAIL, campaign);

export const updateCampaign = async (campaign: Campaign) =>
    await ASRequest.put(`${CAMPAIGN_EMAIL}/${campaign.name}`, campaign);

export const confirmCampaign = async (campaign: string, isMarketing = true) =>
    await ASRequest.post(`${CAMPAIGN_EMAIL}/${campaign}/send?isMarketing=${isMarketing}`);

export const scheduleCampaign = async (campaign: string, date: string, isMarketing = true) =>
    await ASRequest.post(`${CAMPAIGN_EMAIL}/${campaign}/send?isMarketing=${isMarketing}`, date, {
        headers: {
            'Content-Length': 0,
            'Content-Type': 'text/plain'
        }
    });

export const deleteCampaign = async (name: string) =>
    await ASRequest.delete(`${CAMPAIGN_EMAIL}/${name}`);

export const setTemplateToCampaign = async (name: string, template: Template) =>
    await ASRequest.put(`${CAMPAIGN_EMAIL}/${name}`, { name, template });

export const addRecipientsToCampaign = async (name: string, recipients: File) => {
    const formData = new FormData();
    formData.append('recipients', recipients);
    return await ASRequest.put(`${CAMPAIGN_EMAIL}/${name}/recipients`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        timeout: 180000
    });
};

export const getCampaign = async (name: string) =>
    await ASRequest.get(encodeURI(`${CAMPAIGN_EMAIL}/${name}`));

export const deleteCampaignType = async (campaignType: CampaignType) =>
    await ASRequest.delete(`${CAMPAIGN_EMAIL_TYPES}/${campaignType.name}`);

export const typeFromCampaign = (campaign: Campaign): CampaignType => {
    const campaignType: CampaignType = {
        name: campaign.type.trim(),
        tenant: campaign.tenant,
        channel: 'EMAIL',
        isDefault: false,
        defaultTemplate: clone(campaign.template)
    };
    return mergeDeepRight(MINIMAL_FULL_TYPE, campaignType) as CampaignType;
};

export const campaignFromType = (type: CampaignType): Campaign => ({
    name: '',
    tenant: type.tenant ? type.tenant : '',
    template: clone(type.defaultTemplate),
    type: type.name,
    relations: [],
    segments: {
        segmentIds: [],
        resolvedOnSendTime: false,
        initialSegmentsFileName: '',
        invalidEmailCount: 0,
        initialSegmentsRecipientSize: 0
    }
});

export const typesWithSameMetaData = (left: CampaignType, right?: CampaignType) => {
    if (!left || !right) {
        return false;
    }
    return (
        left.name === right.name &&
        left.tenant === right.tenant &&
        eqProps('templateHeader', left.defaultTemplate, right.defaultTemplate)
    );
};

export const sendTestEmail = async (
    templateBody: string,
    templateHeader: TemplateHeader,
    recipients: RecipientTest[],
    plainTextBody: string | null
) =>
    await ASRequest.post(CAMPAIGN_EMAIL_TEST, {
        templateBody,
        templateHeader,
        recipients,
        plainTextBody
    });

export const summarizeEventDescription = async (text: string) => {
    const response = await ASRequest.post<string>(AI_SUMMARIZE_API, {
        text
    });
    return response.data;
};

interface SparkPostIp {
    externalIp: string;
    hostname: string;
    autoWarmupEnabled: boolean;
    autoWarmupStage: number;
}

interface SparkPostIpPool {
    id: string;
    name: string;
    ips: SparkPostIp[];
}

export interface SparkPostIpPoolsResponse {
    results: SparkPostIpPool[];
}

export const getSparkpostIpPools = async () =>
    (await ASRequest.get<SparkPostIpPoolsResponse>(SPARKPOST_LIST_IP_POOLS)).data;

export const replicateCampaign = async (name: string, originalCampaignName: string) => {
    return await ASRequest.post(CAMPAIGN_EMAIL_REPLICATE, {
        name: name,
        originalCampaignName: originalCampaignName
    });
};

export const getSegment = async (segegmentId: string) => {
    const results = await ASRequest.request<any>({
        method: 'get',
        url: `${GET_SEGMENT}/${segegmentId}`
    });
    return results.data;
};

export interface ValidEmailSizes {
    valid_email_size: number;
    invalid_email_size: number;
}

export interface GetCubeQueryEmailSize {
    filters: ASCubeFilter[];
    cubeQueryContext?: QueryParams | null;
    cubeSchemaName: string;
    timezone: string;
}

type DesignContent = {
    slug?: string;
};

type DesignColumn = {
    contents: DesignContent[];
};

type DesignRow = {
    columns: DesignColumn[];
};

export function hasUnsubscribeLink(template?: Template) {
    const rows = template?.design?.body?.rows as DesignRow[];
    if (rows) {
        return rows
            .flatMap((row) => row.columns)
            .flatMap((column) => column.contents)
            .some((content) => content.slug === 'unsubscribe');
    }
    return false;
}

export const unsubscribePresentCheck = (campaign: Campaign) =>
    hasUnsubscribeLink(campaign?.template);

export const comparingArrays = (arrayOne?: string[], arrayTwo?: string[]) => {
    if ((arrayOne || []).length !== (arrayTwo || []).length) {
        return false;
    } else {
        return (arrayOne || []).every((el: string) => (arrayTwo || []).includes(el));
    }
};

export const emailRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z][a-z](?:[a-z]*[a-z])?$/i;

export const handleChange = (
    setTimeSelect: React.Dispatch<React.SetStateAction<Date | null | undefined>>,
    newValue: Date | null | undefined,
    setValue: any
) => {
    setTimeSelect(newValue);
    setValue(newValue);
};

export const scheduleTimeHandler = (
    selectedDate: Date | undefined,
    timeSelect: Date | null | undefined,
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    setTimeDialog: React.Dispatch<React.SetStateAction<boolean>>,
    setDisableConfirm: React.Dispatch<React.SetStateAction<boolean>>
) => {
    if (selectedDate && isAfter(selectedDate, subDays(new Date(), 1)) && timeSelect) {
        const selectedDateToDate = new Date(selectedDate);
        const getHours = timeSelect.getHours();
        const getMinutes = timeSelect.getMinutes();
        const getSeconds = timeSelect.getSeconds();

        const newDateTime = new Date(
            selectedDateToDate.getFullYear(),
            selectedDateToDate.getMonth(),
            selectedDateToDate.getDate(),
            getHours,
            getMinutes,
            getSeconds
        );
        setSelectedDate(newDateTime);
        setTimeDialog(false);
    } else if (selectedDate && isAfter(selectedDate, subDays(new Date(), 1)) && !timeSelect) {
        setSelectedDate(selectedDate);
        setDisableConfirm(false);
    } else {
        if (timeSelect && isAfter(timeSelect, subDays(new Date(), 1))) {
            setSelectedDate(timeSelect);
            setTimeDialog(false);
        } else if (timeSelect && !isAfter(timeSelect, subDays(new Date(), 1))) {
            setSelectedDate(timeSelect);
            setTimeDialog(false);
            setDisableConfirm(true);
        }
    }
};

interface SetOverlayProps {
    overlayShow: boolean;
    additionalOptOutCheck: boolean;
    sentNotification: boolean;
}

export const handleTimeDialogCancel = (
    setSelectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    selectedDate: Date | undefined,
    setTimeDialog: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setSelectedDate(selectedDate);
    setTimeDialog(false);
};

export const confirmAndSendCampaign = (
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    confirmationProps: ConfirmationProps | undefined,
    toPromiseWithFeedback: any,
    isMarketing: boolean | undefined,
    setFeedback: React.Dispatch<React.SetStateAction<Feedback>>,
    setOverlay: React.Dispatch<React.SetStateAction<SetOverlayProps>>,
    overlay: SetOverlayProps
) => {
    setIsLoading(true);
    confirmationProps &&
        toPromiseWithFeedback(
            confirmCampaign(confirmationProps.campaignName as string, isMarketing),
            setFeedback
        )
            .then((res: any) => {
                if (res.data.status === 'confirmed') {
                    setOverlay({
                        ...overlay,
                        overlayShow: true,
                        sentNotification: true,
                        additionalOptOutCheck: true
                    });
                }
            })
            .then(() => setTimeout(() => setIsLoading(false), 5000));
};

export const scheduleAndSendCampaign = (
    selectedDate: Date | undefined,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    confirmationProps: ConfirmationProps | undefined,
    toPromiseWithFeedback: any,
    isMarketing: boolean | undefined,
    setFeedback: React.Dispatch<React.SetStateAction<Feedback>>,
    setOverlay: React.Dispatch<React.SetStateAction<SetOverlayProps>>,
    overlay: SetOverlayProps
) => {
    if (selectedDate) {
        setIsLoading(true);
        const date = selectedDate.toISOString();
        confirmationProps &&
            toPromiseWithFeedback(
                scheduleCampaign(confirmationProps.campaignName as string, date, isMarketing),
                setFeedback
            )
                .then((res: any) => {
                    if (res.data.status === 'draft') {
                        setOverlay({
                            ...overlay,
                            overlayShow: true,
                            sentNotification: true,
                            additionalOptOutCheck: true
                        });
                    }
                })
                .then(() => setTimeout(() => setIsLoading(false), 2000));
    } else {
        console.log('selected date missing');
    }
};

const scheduleAndSendCampaignHandler = (
    selectedDate: Date | undefined,
    isMarketing: boolean | undefined,
    setFeedback: React.Dispatch<React.SetStateAction<Feedback>>,
    setSendEmailPopUp: React.Dispatch<React.SetStateAction<boolean>>,
    setOverlay: React.Dispatch<React.SetStateAction<SetOverlayProps>>,
    overlay: SetOverlayProps,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    confirmationProps: ConfirmationProps | undefined,
    toPromiseWithFeedback: any,
    formatDateTime24h: (date: Date) => string
) => {
    if (selectedDate && isAfter(selectedDate, addMinutes(new Date(), 1).getTime())) {
        scheduleAndSendCampaign(
            selectedDate,
            setIsLoading,
            confirmationProps,
            toPromiseWithFeedback,
            isMarketing,
            setFeedback,
            setOverlay,
            overlay
        );
        setFeedback({ outcome: '', message: '' });
        setSendEmailPopUp(false);
    } else {
        setFeedback({
            outcome: 'error',
            message: `Scheduled time has to be ahead of ${formatDateTime24h(new Date())}`
        });
    }
};

const confirmAndSendCampaignHandler = (
    isMarketing: boolean | undefined,
    setSendEmailPopUp: React.Dispatch<React.SetStateAction<boolean>>,
    setOverlay: React.Dispatch<React.SetStateAction<SetOverlayProps>>,
    overlay: SetOverlayProps,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    confirmationProps: ConfirmationProps | undefined,
    toPromiseWithFeedback: any,
    setFeedback: React.Dispatch<React.SetStateAction<Feedback>>
) => {
    confirmAndSendCampaign(
        setIsLoading,
        confirmationProps,
        toPromiseWithFeedback,
        isMarketing,
        setFeedback,
        setOverlay,
        overlay
    );
    setSendEmailPopUp(false);
};

export const sendCampaignHandler = (
    isMarketing: boolean | undefined,
    scheduledDate: boolean,
    setRedirect: React.Dispatch<React.SetStateAction<boolean>>,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setFeedback: React.Dispatch<React.SetStateAction<Feedback>>,
    setSendEmailPopUp: React.Dispatch<React.SetStateAction<boolean>>,
    setOverlay: React.Dispatch<React.SetStateAction<SetOverlayProps>>,
    overlay: SetOverlayProps,
    selectedDate: Date | undefined,
    confirmationProps: ConfirmationProps | undefined,
    toPromiseWithFeedback: any,
    formatDateTime24h: (date: Date) => string,
    tenantTimezone?: string
) => {
    if (scheduledDate) {
        scheduleAndSendCampaignHandler(
            selectedDate && zonedTimeToUtc(selectedDate, tenantTimezone ?? 'UTC'),
            isMarketing,
            setFeedback,
            setSendEmailPopUp,
            setOverlay,
            overlay,
            setIsLoading,
            confirmationProps,
            toPromiseWithFeedback,
            formatDateTime24h
        );
    } else {
        confirmAndSendCampaignHandler(
            isMarketing,
            setSendEmailPopUp,
            setOverlay,
            overlay,
            setIsLoading,
            confirmationProps,
            toPromiseWithFeedback,
            setFeedback
        );
    }
    setRedirect(true);
    setIsLoading(true);
};

export const selectScheduleDateHandler = (
    setScheduledDate: React.Dispatch<React.SetStateAction<boolean>>,
    setShowSchedule: React.Dispatch<React.SetStateAction<boolean>>
) => {
    setScheduledDate(true);
    setShowSchedule(false);
};

export function countLines(string: string) {
    let count = 1;
    let chr;
    let i = 0;
    const end = string.length;
    for (; i < end; ++i) {
        if (string[i] == '\n' || string[i] == '\r') {
            count = 2;
            chr = string[i];
            break;
        }
    }
    for (++i; i < end; ++i) {
        if (string[i] == chr) {
            ++count;
        }
    }
    return count;
}
