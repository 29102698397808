export const BASE_API_URL = (localStorage.devurl as string) || location.origin;

export const CUBE_API_URL = `${BASE_API_URL}/api/data/v1`;
//export const CUBE_API_URL = `http://localhost:4000/cubejs-api/v1`;

export const GLOBAL_SEARCH_API_URL = `${BASE_API_URL}/api/search/v1/search`;

export const GLOBAL_SEARCH_MULTI_API_URL = `${BASE_API_URL}/api/search/v1/multi-search`;

export const ENTITIES_API_URL = `${BASE_API_URL}/api/graph/v1/batch/entities/traverse`;

export const ENTITIES_API_BATCT_URL = `${BASE_API_URL}/api/graph/v1/batch/entities/aspects`;

export const CDN_URL = `${BASE_API_URL}/api/cdn/download`;

export const OBSERVATIONS_API_URL = `${BASE_API_URL}/api/graph/v1/observations`;

export const USER_MANAGEMENT_API = `${BASE_API_URL}/api/ums`;

export const PROVISIONING_API_URL = `${BASE_API_URL}/api/provisioning/v1`;

export const LOGOUT_URL = `${BASE_API_URL}/logout`;

export const LOGOUT_SUCCESS_CALLBACK_URL = `${BASE_API_URL}/client/logout`;

export const CAMPAIGN_EMAIL_TYPES = `${BASE_API_URL}/api/campaigns/v1/EMAIL/types`;

export const CAMPAIGN_EMAIL_TYPES_DEFAULT = `${BASE_API_URL}/api/campaigns/v1/EMAIL/types/default`;

export const CAMPAIGN_EMAIL = `${BASE_API_URL}/api/campaigns/v1/EMAIL/campaigns`;

export const CAMPAIGN_EMAIL_TYPES_DELETE = `${BASE_API_URL}/api/campaigns/v1/EMAIL/types`;

export const CAMPAIGN_EMAIL_TEST = `${BASE_API_URL}/api/emails/v1/test`;

export const AI_SUMMARIZE_API = `${BASE_API_URL}/api/emails/v1/ai/summarize`;

export const AUTOMATIONS_API_URL = `${BASE_API_URL}/api/emails/v1/automations`;
export const AUTOMATION_EVENTS_API_URL = `${BASE_API_URL}/api/emails/v1/automation-events`;

export const CAMPAIGN_EMAIL_REPLICATE = `${BASE_API_URL}/api/campaigns/v1/EMAIL/campaigns/replicate`;

export const CSV_GLOBAL_UPLOAD = `${BASE_API_URL}/api/campaigns/v1/EMAIL/global-csv`;

export const OPT_INS_API_URL = `${BASE_API_URL}/api/opt-ins/v1`;

export const SEGMENT_MANAGEMENT_URL = `${BASE_API_URL}/api/segments/v1/segments`;

export const GET_SEGMENT = `${BASE_API_URL}/api/segments/v1/segments`;

export const EXPORT_CSV_FACEBOOK = `${BASE_API_URL}/api/segments/v1/segments/export/facebook`;

export const EXPORT_CSV_GOOGLE = `${BASE_API_URL}/api/segments/v1/segments/export/google`;

export const EXPORT_CSV_CUSTOM = `${BASE_API_URL}/api/segments/v1/segments/export/custom`;

export const EXPORT_SEGMENT_BASE = `${BASE_API_URL}/api/segments/v1/segments/export`;

export const GET_CUBE_QUERY_SIZE = `${BASE_API_URL}/api/segments/v1/segments/export/custom/size`;

export const SEGMENT_EXPRESSION_EXPORT_CSV = `${BASE_API_URL}/api/segments/v1/segments/expressions/export/csv`;

export const SEGMENT_EXPRESSION_EXPORT_SIZE = `${BASE_API_URL}/api/segments/v1/segments/expressions/export/size`;

export const SEGMENT_EXPRESSIONS = `${BASE_API_URL}/api/segments/v1/segments/expressions`;

export const GDPR_API_URL = `${BASE_API_URL}/api/anonymize/email`;

export const SHUBERT_STAR_API = `${BASE_API_URL}/api/shubert-star`;

export const CHECK_TEMPLATE_ON_SPARKPOST_API = `${BASE_API_URL}/api/emails/v1/preview`;

export const OPT_IN_TENANTS_URL = `${BASE_API_URL}/api/opt-ins/v1/tenants`;

export const SPARKPOST_LIST_IP_POOLS = `${BASE_API_URL}/api/emails/v1/ip-pools`;

export const EXCHANGE_API_URL = `${BASE_API_URL}/api/exchange/v1`;
