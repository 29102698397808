import React from 'react';
import { UserSettings } from '../components';
import { DynamicRoutes } from 'Routing/DynamicRoutes';
import { MasterPageLayout } from 'layouts/MasterPageLayout';
import { PlaceholderLayout } from 'Routing/PlaceholderLayout';
import Externals from 'externals/Externals';

export type OnDone = React.Dispatch<React.SetStateAction<boolean>>;

interface BootstrapProps {
    children: any;
}

export const Bootstrap = ({ children }: BootstrapProps) => {
    const [userSettingsDone, setUserSettingsDone] = React.useState<boolean>(false);
    const [dynamicRoutes, setDynamicRoutes] = React.useState<boolean>(false);
    const [externalDeps, setExternalDeps] = React.useState<boolean>(false);

    const allDone = (): boolean => userSettingsDone && dynamicRoutes && externalDeps;
    console.log('Bootstrap done?', allDone());
    if (allDone()) {
        return <div>{children}</div>;
    }

    return (
        <React.Fragment>
            <MasterPageLayout content={<PlaceholderLayout />} />
            <UserSettings onDone={setUserSettingsDone} />
            <DynamicRoutes
                onDone={setDynamicRoutes}
                userSettingsDone={userSettingsDone}
            />
            <Externals
                onDone={setExternalDeps}
                userSettingsDone={userSettingsDone}
            />
        </React.Fragment>
    );
};
