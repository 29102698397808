import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { ASLink } from '../common/link/ASLink';
import { sizes, Desktop, Mobile, Notebook } from '../responsive/MediaQuery';
import { DateFormatContext } from '../common/datepicker';
import { StyledTooltip } from '../commonStyles';

interface TileSummaryProps {
    date?: string;
    location: string;
    link: string;
    title: string;
    eventTitle?: string;
}

const ECOTileSummary: React.FC<TileSummaryProps> = ({
    date,
    location,
    link,
    title,
    eventTitle
}) => {
    const { t } = useTranslation();
    const { formatEventDate, formatDate } = React.useContext(DateFormatContext);
    const theme = useTheme();

    const renderDate = (date: string) => <span>{formatEventDate(new Date(date), t)} - </span>;
    return (
        <EventView>
            <Summary>
                {title && title.length > 25 ? (
                    <TitleWrapper>
                        <TooltipItemWrapper
                            data-tip
                            data-for={title}>
                            <CampaignTitle>{title === '' ? t('unknown') : title}</CampaignTitle>
                        </TooltipItemWrapper>
                        <StyledTooltip
                            id={title}
                            place="top"
                            effect="solid"
                            backgroundColor={theme.colors.white}
                            textColor={theme.colors.black}
                            border={true}
                            borderColor={theme.colors.dividers}>
                            {title}
                        </StyledTooltip>
                        <DateWrapper>
                            <CampaignDate>
                                {t('date')}: <b>{date ? formatDate(new Date(date)) : null}</b>
                            </CampaignDate>
                        </DateWrapper>
                    </TitleWrapper>
                ) : (
                    <TitleWrapper>
                        <CampaignTitle>{title === '' ? t('unknown') : title}</CampaignTitle>
                        <CampaignDate>
                            {t('date')}: <b>{date ? formatDate(new Date(date)) : null}</b>
                        </CampaignDate>
                    </TitleWrapper>
                )}
                <Desktop>
                    <CampaignTileInfo>
                        {t('event')}:{' '}
                        <Title
                            href={link}
                            text={eventTitle && eventTitle === '' ? t('unknown') : eventTitle}
                            fontSize={theme.fontSizes.small}
                            noUnderline={true}
                            marginLeft={theme.space.half}
                        />
                    </CampaignTileInfo>
                </Desktop>
                <Notebook>
                    <TileInfo>
                        {date ? renderDate(date) : null}
                        <span>{location}</span>
                    </TileInfo>
                </Notebook>
                <Mobile>
                    <TileInfo>
                        {date ? renderDate(date) : null}
                        <span>{location}</span>
                    </TileInfo>
                </Mobile>
            </Summary>
        </EventView>
    );
};

const Summary = styled('div')`
    justify-content: space-around;
    display: block;
    flex-direction: column;
    height: 100%;
    width: 100%;
    @media (min-width: ${sizes.desktop.minWidth}px) {
    }
`;

const Title = styled(ASLink)<{ fontSize?: string; noUnderline?: boolean; marginLeft?: string }>`
    font-size: ${({ theme, fontSize }) => (fontSize ? fontSize : theme.fontSizes.header)};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: ${({ noUnderline }) => (noUnderline ? 'none' : 'underline')};
    margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'none')};
    width: 100%;

    @media (max-width: ${sizes.desktop.minWidth}px) {
        word-break: break-word;
    }
`;

const TileInfo = styled('div')`
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const EventView = styled('div')`
    display: flex;
    padding-bottom: 0;
    justify-self: start;
    grid-auto-flow: column;
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid ${({ theme }) => theme.colors.dividers};
    margin-top: ${({ theme }) => theme.space.half};
    width: 100%;
`;

const TooltipItemWrapper = styled('div')`
    min-width: 0;
    flex: 1;
    margin-right: ${({ theme }) => theme.space.single};
    & > p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const TitleWrapper = styled('div')`
    display: flex;
    width: 87%;
    justify-content: space-between;
`;

const CampaignDate = styled('span')`
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.small};
    margin-top: 4px;
    padding-left: 14px;
    column-gap: 10px;
`;

const CampaignTileInfo = styled('div')`
    display: flex;
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const CampaignTitle = styled('p')`
    font-size: ${({ theme }) => theme.fontSizes.header};
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const DateWrapper = styled('div')`
    white-space: nowrap;
    & > span {
        display: inline-block;
    }
`;

export { ECOTileSummary };
