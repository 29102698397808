import {
    AvailableDimensions,
    AvailableSchemas,
    DimensionInfo,
    FullDimension
} from 'stores/dimensionData';
import { FeatureFlags } from 'stores/settings';
import { ASCubeFilter, DimensionData } from 'utils/common/types';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { toNumber } from 'utils/mappers';
import { Maybe } from 'utils/maybe';

export const accessPointFromDom =
    "const point = this.closest('.highcharts-container').getElementsByClassName('highcharts-point')[Array.prototype.indexOf.call(this.parentElement.parentElement.children, this.parentElement)].point;";

export const onBarClickScript = `${accessPointFromDom} point.series.hcEvents.click[0].fn({point});`;

export const onMouseOverScript = `${accessPointFromDom} point.onMouseOver(this);`;

export function filterUnknown(
    dimension: FullDimension,
    dimensionData: DimensionInfo,
    data: DimensionData[]
): DimensionData[] {
    if (dimensionData.hideUnknownAndFalsyValues) {
        return data.filter(
            (d) =>
                !!d[dimension] &&
                d[dimension] !== 'Unknown' &&
                d[dimension] !== 'unknown' &&
                d[dimension] !== '(not set)'
        );
    } else if (dimensionData.hideFalsyValues) {
        return data.filter((d) => !!d[dimension]);
    }
    return data;
}

export function orderBinaryChartData(
    data: DimensionData[],
    dimensionData: DimensionInfo,
    dimension: FullDimension
): DimensionData[] {
    if (dimensionData.exactDataOrder) {
        return data.sort((a, b) => {
            const aIndex = (dimensionData.exactDataOrder || []).indexOf(String(a[dimension]));
            const bIndex = (dimensionData.exactDataOrder || []).indexOf(String(b[dimension]));
            return (aIndex === -1 ? Infinity : aIndex) - (bIndex === -1 ? Infinity : bIndex);
        });
    }
    return data.sort((a, b) => String(a).localeCompare(String(b)));
}

export function orderAmountSpentBuckets(
    data: DimensionData[],
    dimensionData: DimensionInfo,
    dimension: FullDimension
): DimensionData[] {
    return data.sort(
        (a, b) =>
            toNumber(String(a[dimension]).split('-')[0]) -
            toNumber(String(b[dimension]).split('-')[0])
    );
}

export function orderLinearData(
    data: DimensionData[],
    { maxValue, minValue }: DimensionInfo,
    dimension: FullDimension
) {
    return data.sort((a, b) => {
        let numbA = Number(a[dimension]);
        let numbB = Number(b[dimension]);
        if (maxValue) {
            numbA = a[dimension] === maxValue ? Infinity : numbA;
            numbB = b[dimension] === maxValue ? Infinity : numbB;
        }
        if (minValue) {
            numbA = a[dimension] === minValue ? -Infinity : numbA;
            numbB = b[dimension] === minValue ? -Infinity : numbB;
        }
        return numbA - numbB;
    });
}

export const transformDimension = (
    dimension: FullDimension,
    features: FeatureFlags
): FullDimension => {
    const [cube, dimensionName] = dimension.split('.') as [AvailableSchemas, AvailableDimensions];
    if (dimensionName === 'distanceToVenue') {
        return features.useMiles
            ? `${cube}.distanceToVenueInMiles`
            : `${cube}.distanceToVenueInKms`;
    }
    if (dimensionName === 'distanceToVenueGrouped') {
        if (features.useMiles) {
            return `${cube}.distanceToVenueMilesGrouped`;
        }
        return `${cube}.distanceToVenueKmsGrouped`;
    }
    if (dimensionName === 'distanceToVenueRounded') {
        if (features.useMiles) {
            return `${cube}.distanceToVenueInMilesRounded`;
        }
        return `${cube}.distanceToVenueInKmsRounded`;
    }
    if (dimensionName === 'complimentary') {
        if (features.complimentary) {
            return dimension;
        }
        return `${cube}.paidVsFree`;
    }
    return dimension;
};

// Call this to lower the tooltip position if it's too close to the top of the screen:
export const getTooltipTopPositionAdjustment = (container: HTMLElement, labelHeight: number) => {
    // Calculate the offset of the container element relative to the screen:
    const containerRect = container.getBoundingClientRect();
    const distFromScreenTop = containerRect.top - 44; // Acount for the 44px tall menu bar on top.

    const adjustment = distFromScreenTop - labelHeight < 0 ? labelHeight - distFromScreenTop : 0;

    return adjustment;
};

export const getFilterValues = (filters: ASCubeFilter[], dimension: FullDimension): string[] => {
    const filterValues = Maybe(filters.find((filter) => filter.dimension === dimension))
        .chain((filter) => Maybe(filter.values))
        .map((values) => values.map((value) => filterValueCheck(value)))
        .getOrElse([] as string[]);

    return filterValues;
};
