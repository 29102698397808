import React from 'react';
import { useTheme } from '@mui/material/styles';
import { compose, pathOr } from 'ramda';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { ChartTitle, BinaryRow } from '../charts';
import { LoadingContainer } from '../common/loader';
import { Portlet } from '../responsive';
import { getDimensionQuery } from 'utils/queries';
import { cubejsApi } from 'utils/api/CubeAPI';

import { Maybe } from 'utils/maybe';

import { AvailableDimensions, DimensionDataStore } from 'stores/dimensionData';

import { settingsStore } from 'stores/settings';

import { DimensionData, BinaryFilterProps } from 'utils/common/types';
import { mapBinaryFilterChartData } from 'utils/mappers';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { Tracking } from 'externals/tracking';
import { filterUnknown, transformDimension } from 'utils/chartUtils';

const BinaryFilter = ({
    baseFilters,
    dimension,
    filters,
    metric,
    onFilter,
    transformChartLabel,
    salesTime,
    timePeriod,
    includePortlet,
    portletTitle,
    measures,
    usePercentage,
    minimumFractionDigits,
    cursorDefault = false,
    isFilterable = true,
    queryParams = {}
}: BinaryFilterProps) => {
    const { excludeDashboardComponent, featureFlags, tenantTimezone } =
        React.useContext(settingsStore);
    const dimensionData = React.useContext(DimensionDataStore);

    const [rawData, setRawData] = React.useState<DimensionData[]>([]);
    const [tickets, setTickets] = React.useState<Highcharts.Options['series']>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const actualDimension = transformDimension(dimension, featureFlags);
    const [_, dimensionName]: [string, AvailableDimensions] = actualDimension.split('.') as [
        string,
        AvailableDimensions
    ];
    if (!transformChartLabel && dimensionData[dimensionName].labelFormatter) {
        transformChartLabel = dimensionData[dimensionName].labelFormatter;
    }

    React.useEffect(() => {
        if (excludeDashboardComponent.includes(dimensionName)) {
            return;
        }

        setLoading(true);
        cubejsApi
            .load(
                getDimensionQuery(
                    actualDimension,
                    measures,
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    tenantTimezone
                ),
                { queryParams }
            )
            .then(
                compose(
                    setRawData,
                    (data) => filterUnknown(actualDimension, dimensionData[dimensionName], data),
                    pathOr<DimensionData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [salesTime, timePeriod, filters, metric, excludeDashboardComponent]);

    const theme = useTheme();
    const { t } = useTranslation();
    const title = dimensionData[dimensionName].label;
    const filterValues = Maybe(filters.find((filter) => filter.dimension === actualDimension))
        .chain((filter) => Maybe(filter.values))
        .map((values) => values.map((value) => filterValueCheck(value)))
        .getOrElse([] as string[]);

    React.useEffect(() => {
        setTickets(
            mapBinaryFilterChartData(
                t,
                rawData,
                filterValues,
                actualDimension,
                metric,
                theme,
                dimensionData[dimensionName],
                transformChartLabel
            )
        );
    }, [rawData, metric, filters, timePeriod, salesTime]);

    const handleFiltering = React.useRef((event: Highcharts.SeriesClickEventObject) => {
        const filterValue = String(event.point.options.custom?.filterValue);
        const breadcrumbValue = event.point.options.custom?.breadcrumbValue;

        if (isFilterable) {
            onFilter({
                type: 'dimensionClick',
                operator: 'equals',
                dimension: actualDimension,
                value: {
                    value: filterValue,
                    label: breadcrumbValue
                }
            });

            Tracking.trackGoal('Filtered by dimension', {
                posthogUniqueKey: dimensionData[dimensionName].label,
                dimension,
                chartName: t(dimensionData[dimensionName].label),
                filters: filterValueCheck(breadcrumbValue)
            });
        }
    }).current;

    if (excludeDashboardComponent.includes(dimensionName)) {
        return <React.Fragment />;
    }

    const body = (
        <ComponentWrapper>
            <ChartTitle
                key="title"
                title={t(title)}
            />
            <BinaryRow
                key="chart"
                handleFilter={handleFiltering}
                tooltipLabel={title}
                series={tickets && tickets.reverse()}
                metric={metric}
                usePercentage={usePercentage}
                minimumFractionDigits={minimumFractionDigits}
                cursorDefault={cursorDefault}
                labelFormatter={dimensionData[dimensionName].labelFormatter}
                tooltipDecimals={1}
            />
            {loading && <LoadingContainer key="loader" />}
        </ComponentWrapper>
    );

    if (includePortlet && portletTitle) {
        return <Portlet title={portletTitle}>{body}</Portlet>;
    }
    return body;
};

const ComponentWrapper = styled('div')`
    position: relative;
`;

export { BinaryFilter };
