import { User } from 'utils/api/userManagement/types';

const featureName = 'inlinemanual';
const apiKeyEnvVar = `${featureName.toUpperCase()}_API_KEY`;
const API_KEY = '2480743a41372950b2948f4d964741fd';

declare global {
    interface Window {
        inlineManualPlayerData: any;
        inlineManualTracking: any;
        inline_manual_player: any;
        createInlineManualPlayer(data: any): void;
        inlineManualOptions: { language: string };
    }
}

function insertScript(url: string) {
    return new Promise((resolve) => {
        const tagName = 'script';
        const e = document.createElement(tagName);
        const t: HTMLHeadElement = document.getElementsByTagName('head')[0];

        // If we don't find a body node, there's something strange going on
        if (!t) {
            return;
        }

        e.async = true;
        e.src = url;
        e.charset = 'UTF-8';
        e.onload = resolve as any;
        t.appendChild(e);
    });
}

export const initFeature = async ({
    currentUser,
    currentUserRoles,
    tenant,
    onDone,
    language
}: {
    currentUser: User | null;
    currentUserRoles: string[];
    tenant: string;
    onDone: React.Dispatch<React.SetStateAction<boolean>>;
    language: string;
}) => {
    const apiKey = process.env[apiKeyEnvVar] || API_KEY;
    const url = `https://inlinemanual.com/embed/player.${apiKey}.bare.js`;

    const userConfig = () => ({
        uid: currentUser && currentUser.email,
        email: currentUser && currentUser.email,
        username: currentUser && currentUser.nickname,
        name: currentUser && currentUser.name,
        roles: currentUserRoles,
        tenant: tenant,
        maintenant: tenant,
        env: '',
        url: new URL(window.location.href).hostname,
        version2: true
    });

    window.inlineManualTracking = userConfig();

    try {
        await insertScript(url);
        window.inlineManualOptions = { language };
        window.createInlineManualPlayer(window.inlineManualPlayerData);
        onDone(true);
    } catch (error) {
        console.log('Error in initFeature', JSON.stringify(error));

        onDone(true);
    }
};
