import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Authentication/AuthContext';
import './UserProfilePic.scss';
import { NO_USER_PIC_ICON } from 'assets/iconConstants';
import { CustomSvgIcon } from 'utils/CustomSvgIcon';

export const UserProfilePic = () => {
    const { user } = React.useContext(AuthContext);
    const theme = useTheme();
    const [menuOpen, setMenuOpen] = React.useState<boolean>(false);

    const openUserProfile = () => {
        setMenuOpen(true);
    };

    const isValidUrl = (url: string) => {
        try {
            new URL(url);
        } catch (e) {
            return false;
        }

        return true;
    };

    const isPictureValid = (pictureUrl: string) => () => {
        return isValidUrl(pictureUrl);
    };

    return (
        <div
            className="wrapper"
            onClick={openUserProfile}
            onMouseEnter={() => {
                setMenuOpen(true);
            }}
            onMouseLeave={() => {
                setMenuOpen(false);
            }}>
            <div className="icon">
                {!user || !user.picture || !isPictureValid(user.picture) ? (
                    <CustomSvgIcon
                        size={2.0}
                        fill={theme.colors.shadowImage}
                        iconName={NO_USER_PIC_ICON}
                    />
                ) : (
                    <img
                        src={user.picture}
                        alt={user.name}
                    />
                )}
            </div>
            {menuOpen && <UserMenu setMenuOpen={setMenuOpen} />}
        </div>
    );
};

const UserMenu = ({
    setMenuOpen
}: {
    setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const { t } = useTranslation();

    return (
        <div
            className="menu"
            onMouseEnter={() => setMenuOpen(true)}
            onMouseLeave={() => setMenuOpen(false)}>
            <div className="title">{t('userMenu')}</div>

            <Link
                className="link"
                to="/personal_settings">
                {t('personalSettings')}
            </Link>

            <Link
                className="link"
                to="/client/logout">
                {t('Logout')}
            </Link>
        </div>
    );
};
