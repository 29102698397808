import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ascend } from 'ramda';
import { Routes } from '../Routing/routes';
import { MasterPageLayout } from 'layouts/MasterPageLayout';
import { HomeButton } from 'components/buttons';
import { UserProfilePic } from 'components/icons/UserProfilePic';
import { SideBarMenu, Menu } from 'components/common/menu/SideBarMenu';
import { DynamicRoutesContext } from 'Routing/DynamicRoutesContext';
import {
    LIVEINFO_ICON,
    HASH_ICON,
    WAND_ICON,
    LIGHTBULB_ICON,
    GEARS_ICON,
    WRENCH_ICON,
    HELP_ICON
} from 'assets/iconConstants';
import { settingsStore } from 'stores/settings';
import { capitalize } from 'utils/formatting/text';
import { Mobile, TabletToDesktop } from 'components/responsive/MediaQuery';

export const LayoutTemplate = () => {
    const { t } = useTranslation();
    const { dashboardGroups } = React.useContext(DynamicRoutesContext);

    const { tenantDisplayName } = React.useContext(settingsStore);

    const menuItems = dashboardGroups
        .sort(ascend(([dashboardGroup]) => DashbarodGroupOrder.indexOf(dashboardGroup)))
        .map(([dashboardGroup, routes]) => {
            const menuItem: Partial<Menu> =
                getMenuItemByDashbarodGroup[dashboardGroup as dashboardGroup];
            menuItem.links = routes.map((route) => ({
                href: route.path,
                label: t(route.label || ''),
                id: route.label || ''
            }));

            return menuItem as Menu;
        });

    menuItems.push({
        icon: HELP_ICON,
        title: 'help',
        links: [
            {
                label: t('helpPortal'),
                target: '_blank',
                href: 'https://support.activitystream.com/',
                id: 'helpPortal-link'
            }
        ],
        id: 'helpPortal'
    });

    useEffect(() => {
        document.title = `${capitalize(tenantDisplayName)} - Activity Stream`;
    }, [tenantDisplayName, t]);

    const composedMenu = () => (
        <div>
            <Mobile>
                <UserProfilePic />
                <SideBarMenu menus={menuItems} />
            </Mobile>

            <TabletToDesktop>
                <HomeButton />
                <UserProfilePic />
                <SideBarMenu menus={menuItems} />
            </TabletToDesktop>
        </div>
    );

    return (
        <MasterPageLayout
            menu={composedMenu()}
            content={<Routes />}
        />
    );
};

const getMenuItemByDashbarodGroup: { [key: string]: Partial<Menu> } = {
    Dashboards: {
        icon: LIVEINFO_ICON,
        title: 'dashboards'
    },
    Channels: {
        icon: HASH_ICON,
        title: 'channels-menu'
    },
    Tools: {
        icon: WAND_ICON,
        title: 'tools'
    },
    Intelligence: {
        icon: LIGHTBULB_ICON,
        title: 'intelligence'
    },
    Settings: {
        icon: GEARS_ICON,
        title: 'settings'
    },
    UnderTheHood: {
        icon: WRENCH_ICON,
        title: 'underTheHood'
    }
};

const DashbarodGroupOrder = Object.keys(getMenuItemByDashbarodGroup);

type dashboardGroup =
    | 'Dashboards'
    | 'Channels'
    | 'Tools'
    | 'Intelligence'
    | 'Settings'
    | 'UnderTheHood'
    | 'Help';
