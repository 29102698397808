import React from 'react';
import { TFunction } from 'i18next';
import { orderBinaryChartData, orderAmountSpentBuckets, orderLinearData } from 'utils/chartUtils';
import { DimensionData } from 'utils/common/types';
import {
    addCurrencyAmountSpent,
    affirmativeTransform,
    distanceRoundedFormatter,
    numberOfEventsPurchased,
    transformCustomerCategoriesChartLabel,
    transformCustomerTagsChartLabel,
    transformEmailOpts,
    transformGender
} from 'utils/chartLabelFormatters';

export type AvailableSchemas =
    | 'Ad'
    | 'AdGroup'
    | 'BaseDemography'
    | 'BaseProducts'
    | 'BaseTransactions'
    | 'Campaign'
    | 'CampaignConnectedEntities'
    | 'CampaignProducts'
    | 'Customers'
    | 'CustomersWithEmailAndPhone'
    | 'DonationProducts'
    | 'Donations'
    | 'EmailCampaignEvents'
    | 'Emails'
    | 'Events'
    | 'GdprItems'
    | 'Inventory'
    | 'InventoryOverTime'
    | 'MarketingStatistics'
    | 'MarketingStatisticsTransactions'
    | 'Merchandise'
    | 'MerchandiseMeta'
    | 'NextVisit'
    | 'Observations'
    | 'Offer'
    | 'Order'
    | 'Payments'
    | 'Products'
    | 'Retailers'
    | 'SalesChannels'
    | 'SegmentBoughtXbutNotY'
    | 'SegmentCustomerValidTickets'
    | 'Tickets'
    | 'TicketsStatus'
    | 'Transactions'
    | 'TransactionsAndCustomers'
    | 'SegmentCustomersCertainTimePeriod'
    | 'EmailsWhoHaveNotBought';

export type FullDimension = `${AvailableSchemas}.${AvailableDimensions}`;

export type AmountSpentBucketsDimension =
    | 'amountSpentBuckets10'
    | 'amountSpentBuckets25'
    | 'amountSpentBuckets50'
    | 'amountSpentBuckets100'
    | 'amountSpentBuckets250'
    | 'amountSpentBuckets500'
    | 'amountSpentBuckets1000'
    | 'amountSpentBuckets2500'
    | 'amountSpentBuckets5000'
    | 'amountSpentBuckets10000';

export type AvailableDimensions =
    | 'transactionType'
    | 'itemCount'
    | 'complimentary'
    | 'priceCategory'
    | 'priceType'
    | 'revenue'
    | 'productArchetype'
    | 'deliveryMethod'
    | 'itemPrice'
    | 'averageLeadTime'
    | 'occurredAtYear'
    | 'occurredAtMonth'
    | 'occurredAtQuarter'
    | 'occurredAtDayOfWeek'
    | 'averageTicketPrice'
    | 'productRef'
    | 'buyerRef'
    | 'productType'
    | 'deviceTypeKeyFigures'
    | 'retailerGroupsLabel'
    | 'leadTimeGroupedByTen'
    | 'deviceBrowser'
    | 'deviceType'
    | 'origin'
    | 'offerLabel'
    | 'section'
    | 'venueRef'
    | 'age'
    | 'country'
    | 'city'
    | 'state'
    | 'postalCode'
    | 'customerTagsFlat'
    | 'customerLabel'
    | 'gender'
    | 'education'
    | 'income'
    | 'customSegment'
    | 'countryCode'
    | 'distinctProduct'
    | 'audienceAgency'
    | 'amountSpent'
    | 'fullPostalCode'
    | 'hascCode'
    | 'hascCode2'
    | 'address'
    | 'phoneMobile'
    | 'emailAddress'
    | 'isValidEmail'
    | 'emailMarketingPermission'
    | 'productLabel'
    | 'venueLabel'
    | 'salesChannelType'
    | 'distanceToVenueGrouped'
    | 'distanceToVenueMilesGrouped'
    | 'distanceToVenueKmsGrouped'
    | 'classificationCategories'
    | 'performerRefFlat'
    | 'performerLabelFlat'
    | 'utmReferrer'
    | 'promoterLabelFlat'
    | 'leadTimeWeekGrouped'
    | 'hallLabel'
    | 'paymentMethod'
    | 'salesChannel'
    | 'leadTime'
    | 'variant'
    | 'partition'
    | 'subtenant'
    | 'productionLabel'
    | 'retailerLabel'
    | 'adGroupRef'
    | 'adRef'
    | 'distanceToVenueInMilesRounded'
    | 'distanceToVenueInMiles'
    | 'distanceToVenueInKms'
    | 'productArchetypeVariant'
    | 'orderRef'
    | 'distanceToVenueInKmsRounded'
    | 'distanceToVenueRounded'
    | 'distanceToVenue'
    | 'timedBeginsStartOfDay'
    | 'amount'
    | 'oneTimeAndRepeatDonors'
    | 'groupedAge'
    | 'utmCampaign'
    | 'paidVsFree'
    | 'firstName'
    | 'lastName'
    | 'keyFiguresAge'
    | 'campaignRef'
    | 'groupedGender'
    | 'hasEmailAddress'
    | 'campaignType'
    | 'addressMainCity'
    | 'addressMainPostalCode'
    | 'campaignLinkName'
    | 'campaignLinkUrl'
    | 'addressMainCountryCode'
    | 'addressMainHasc'
    | 'addressMainHasc2'
    | 'timedBeginsDayOfWeek'
    | 'campaignSentAtDayOfWeek'
    | 'customerRef'
    | 'utmMedium'
    | 'utmSource'
    | 'productionSeason'
    | 'productionPackage'
    | 'customerClassificationCategoriesFlat'
    | 'donationLabel'
    | 'adGroupLabel'
    | 'adLabel'
    | 'merchandiseClassificationType'
    | 'merchandiseLabel'
    | 'productClassificationType'
    | 'productClassificationCategoriesFlat'
    | 'tags'
    | 'customerLabel'
    | 'entityRef'
    | 'gender'
    | 'countryCode'
    | 'tagsArray'
    | 'audienceAgency'
    | 'distinctProduct'
    | 'netItemCount'
    | AmountSpentBucketsDimension;

const distanceOrder = Array.from(Array(21).keys()).map((d) => {
    if (d === 20) {
        return '200+';
    } else {
        return String(d * 10);
    }
});

export interface DimensionInfo {
    label: string;
    labelFormatter?: (value: string | number, t: TFunction) => string;
    hideUnknownAndFalsyValues?: boolean;
    hideFalsyValues?: boolean;
    maxValue?: string | number;
    minValue?: string | number;
    orderData?: (
        data: DimensionData[],
        dimensionData: DimensionInfo,
        dimension: FullDimension
    ) => DimensionData[];
    exactDataOrder?: Array<string>;
    color?: string;
}

export type State = { [key in AvailableDimensions]: DimensionInfo };

const amountSpentBuckets = {
    hideUnknownAndFalsyValues: true,
    labelFormatter: addCurrencyAmountSpent,
    orderData: orderAmountSpentBuckets,
    color: 'filterGroupCustomer'
};

const initialState: State = {
    transactionType: { label: 'transactionType', color: 'filterGroupTransaction' },
    itemCount: {
        label: 'itemCount'
    },
    complimentary: {
        label: 'complimentary',
        labelFormatter: affirmativeTransform,
        orderData: orderBinaryChartData,
        exactDataOrder: ['0', '1']
    },
    paidVsFree: {
        label: 'paidVsFree',
        orderData: orderBinaryChartData,
        exactDataOrder: ['Paid', 'Free']
    },
    productClassificationType: { label: 'productClassificationType', color: 'filterGroupProduct' },
    priceCategory: { label: 'priceCategory' },
    priceType: { label: 'priceType' },
    revenue: { label: 'revenue' },
    productArchetype: { label: 'items' },
    deliveryMethod: { label: 'deliveryMethod' },
    itemPrice: { label: 'itemPrice' },
    occurredAtYear: { label: 'occurredAtYear' },
    occurredAtMonth: { label: 'occurredAtMonth' },
    occurredAtQuarter: { label: 'occurredAtQuarter' },
    averageLeadTime: { label: 'averageLeadTime' },
    averageTicketPrice: { label: 'averageTicketPrice' },
    distanceToVenueGrouped: {
        label: 'distanceFromVenue',
        hideUnknownAndFalsyValues: true
    },
    distanceToVenueMilesGrouped: {
        label: 'distanceFromVenueInMiles',
        orderData: orderBinaryChartData,
        exactDataOrder: ['0 - 10', '10 - 25', '25 - 50', '50 - 100', '100 - 200', '> 200'],
        hideUnknownAndFalsyValues: true
    },
    distanceToVenueKmsGrouped: {
        label: 'distanceFromVenueInKm',
        orderData: orderBinaryChartData,
        exactDataOrder: ['0 - 10', '10 - 25', '25 - 50', '50 - 100', '100 - 200', '> 200'],
        hideUnknownAndFalsyValues: true
    },
    productRef: { label: 'product' },
    buyerRef: { label: 'buyer' },
    productType: { label: 'salesByProduct' },
    deviceTypeKeyFigures: { label: 'deviceType' },
    retailerGroupsLabel: { label: 'retailer' },
    leadTimeGroupedByTen: {
        label: 'leadTimeDistribution',
        orderData: orderLinearData,
        maxValue: '300+'
    },
    deviceBrowser: { label: 'deviceBrowser', color: 'filterGroupCommunication' },
    deviceType: { label: 'deviceType', color: 'filterGroupCommunication' },
    origin: { label: 'sourceOfData', color: 'filterGroupCommunication' },
    offerLabel: { label: 'offer', hideUnknownAndFalsyValues: true },
    section: { label: 'section', hideFalsyValues: true, color: 'filterGroupCustomer' },
    venueRef: { label: 'venueRef' },
    age: { label: 'customersByAge' },
    country: { label: 'country', hideUnknownAndFalsyValues: true, color: 'filterGroupCustomer' },
    city: { label: 'city', hideUnknownAndFalsyValues: true, color: 'filterGroupCustomer' },
    state: { label: 'state', hideUnknownAndFalsyValues: true, color: 'filterGroupCustomer' },
    postalCode: { label: 'postalCode', hideUnknownAndFalsyValues: true },
    education: { label: 'education', hideUnknownAndFalsyValues: true },
    income: { label: 'income', hideUnknownAndFalsyValues: true },
    customSegment: { label: 'Custom Segment' },
    customerTagsFlat: {
        label: 'customerTagsFlat',
        hideUnknownAndFalsyValues: true,
        labelFormatter: transformCustomerTagsChartLabel,
        color: 'filterGroupCustomer'
    },
    tags: {
        label: 'customerTags',
        hideUnknownAndFalsyValues: true,
        labelFormatter: transformCustomerTagsChartLabel
    },
    customerLabel: { label: 'customer' },
    customerRef: { label: 'customerRef' },
    entityRef: { label: 'customerEntityRef' },
    gender: { label: 'gender', labelFormatter: transformGender, color: 'filterGroupCustomer' },
    countryCode: { label: 'countryCode' },
    tagsArray: { label: 'tagsArray' },
    audienceAgency: {
        label: 'audienceAgency',
        hideUnknownAndFalsyValues: true,
        color: 'filterGroupCustomer'
    },
    distinctProduct: {
        label: 'customersByNumberOfEventsPurchased',
        labelFormatter: numberOfEventsPurchased,
        hideUnknownAndFalsyValues: true,
        orderData: orderLinearData,
        color: 'filterGroupCustomer'
    },
    amountSpent: { label: 'customersByAmountSpent' },
    amountSpentBuckets10: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets10' },
    amountSpentBuckets25: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets25' },
    amountSpentBuckets50: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets50' },
    amountSpentBuckets100: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets100' },
    amountSpentBuckets250: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets250' },
    amountSpentBuckets500: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets500' },
    amountSpentBuckets1000: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets1000' },
    amountSpentBuckets2500: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets2500' },
    amountSpentBuckets5000: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets5000' },
    amountSpentBuckets10000: { ...amountSpentBuckets, label: 'customersByAmountSpentBuckets10000' },
    fullPostalCode: {
        label: 'fullPostalCode',
        hideUnknownAndFalsyValues: true,
        labelFormatter: (label) => {
            return String(label).substr(3);
        },
        color: 'filterGroupCustomer'
    },
    hascCode: { label: 'hasCountyCode' },
    hascCode2: { label: 'hasStateCode' },
    productLabel: { label: 'productLabel', color: 'filterGroupCustomer' },
    venueLabel: {
        label: 'venueLabel',
        hideUnknownAndFalsyValues: true,
        color: 'filterGroupProduct'
    },
    paymentMethod: { label: 'paymentMethod' },
    salesChannel: { label: 'salesChannel' },
    salesChannelType: { label: 'salesChannelType' },
    classificationCategories: { label: 'subGenre' },
    performerRefFlat: { label: 'performerRef' },
    performerLabelFlat: { label: 'performer', hideFalsyValues: true, color: 'filterGroupProduct' },
    promoterLabelFlat: { label: 'promoter', color: 'filterGroupProduct' },
    productionSeason: { label: 'Season', color: 'filterGroupProduct' },
    productionPackage: { label: 'Package', color: 'filterGroupProduct' },
    utmReferrer: { label: 'referrer' },
    leadTime: { label: 'averageLeadTimeDistribution' },
    leadTimeWeekGrouped: {
        label: 'averageLeadTimeDistributionByWeek',
        orderData: orderLinearData,
        maxValue: '52+'
    },
    variant: { label: 'priceCodeType' },
    productClassificationCategoriesFlat: { label: 'eventCategory', color: 'filterGroupProduct' },
    hallLabel: { label: 'hallRef', hideFalsyValues: true, color: 'filterGroupProduct' },
    phoneMobile: { label: 'phone' },
    emailAddress: { label: 'email' },
    isValidEmail: { label: 'isValidEmail' },
    emailMarketingPermission: {
        label: 'marketingPermission',
        labelFormatter: transformEmailOpts
    },
    address: { label: 'address' },
    occurredAtDayOfWeek: { label: 'dayOfWeek' },
    merchandiseLabel: { label: 'merchandiseLabel' },
    merchandiseClassificationType: {
        label: 'merchandiseClassificationType',
        color: 'filterGroupCustomer'
    },
    partition: { label: 'partition', color: 'filterGroupProduct' },
    subtenant: {
        label: 'subtenant'
    },
    productionLabel: {
        label: 'production',
        hideUnknownAndFalsyValues: true,
        color: 'filterGroupProduct'
    },
    retailerLabel: { label: 'outlet', color: 'filterGroupTransaction' },
    adGroupRef: { label: 'adgroup_label' },
    adRef: { label: 'ad_ref' },
    adLabel: { label: 'ad_label', hideUnknownAndFalsyValues: true },
    adGroupLabel: { label: 'adgroup_label', hideUnknownAndFalsyValues: true },
    distanceToVenueInMilesRounded: {
        hideUnknownAndFalsyValues: true,
        label: 'distanceToVenueInMilesRounded',
        labelFormatter: distanceRoundedFormatter,
        orderData: orderBinaryChartData,
        exactDataOrder: distanceOrder
    },
    distanceToVenueInMiles: {
        label: 'distanceToVenueInMiles',
        hideUnknownAndFalsyValues: true
    },
    distanceToVenueInKmsRounded: {
        hideUnknownAndFalsyValues: true,
        label: 'distanceToVenueInKmsRounded',
        labelFormatter: distanceRoundedFormatter,
        orderData: orderBinaryChartData,
        exactDataOrder: distanceOrder,
        color: 'filterGroupCustomer'
    },
    distanceToVenueInKms: { label: 'distanceToVenueInKms', hideUnknownAndFalsyValues: true },
    productArchetypeVariant: { label: 'productType' },
    orderRef: { label: 'itemsPerOrder' },
    distanceToVenueRounded: {
        hideUnknownAndFalsyValues: true,
        label: 'distanceToVenueRounded',
        orderData: orderBinaryChartData,
        exactDataOrder: distanceOrder,
        color: 'filterGroupCustomer'
    },
    distanceToVenue: {
        hideUnknownAndFalsyValues: true,
        label: 'distanceToVenue',
        labelFormatter: distanceRoundedFormatter
    },
    timedBeginsStartOfDay: { label: 'timedBegins' },
    amount: {
        label: 'amount',
        hideUnknownAndFalsyValues: true,
        orderData: orderAmountSpentBuckets
    },
    oneTimeAndRepeatDonors: { label: 'repeatDonors', hideUnknownAndFalsyValues: true },
    donationLabel: { label: 'fund' },
    groupedAge: {
        label: 'customersByAgeGrouped',
        hideUnknownAndFalsyValues: true,
        orderData: orderLinearData,
        maxValue: '70+',
        minValue: '<16',
        color: 'filterGroupCustomer'
    },
    utmCampaign: {
        label: 'campaign',
        hideUnknownAndFalsyValues: true,
        color: 'filterGroupCommunication'
    },
    utmSource: { label: 'source' },
    firstName: { label: 'firstName' },
    lastName: { label: 'lastName' },
    keyFiguresAge: { label: 'customersKeyFiguresAge' },
    campaignRef: { label: 'campaignRef', hideUnknownAndFalsyValues: true },
    groupedGender: { label: 'groupedGender', color: '' },
    hasEmailAddress: {
        label: 'hasEmail',
        labelFormatter: affirmativeTransform
    },
    campaignType: { label: 'campaignType', color: 'filterGroupCommunication' },
    addressMainCity: { label: 'mainAddressHasCity', color: 'filterGroupCustomer' },
    addressMainPostalCode: { label: 'mainAddressPostalCode', color: 'filterGroupCustomer' },
    campaignLinkName: { label: 'campaignLinkName' },
    campaignLinkUrl: { label: 'campaignLinkUrl', color: 'filterGroupCustomer' },
    addressMainCountryCode: { label: 'mainAddressCountryCode' },
    addressMainHasc: { label: 'mainAddressHasCounty' },
    addressMainHasc2: { label: 'addressHasState' },
    customerClassificationCategoriesFlat: {
        label: 'customerCategories',
        hideUnknownAndFalsyValues: true,
        labelFormatter: transformCustomerCategoriesChartLabel,
        color: 'filterGroupCustomer'
    },
    timedBeginsDayOfWeek: { label: 'timedBeginsDayOfWeek' },
    campaignSentAtDayOfWeek: { label: 'campaignSentAtDayOfWeek' },
    utmMedium: { label: 'medium', hideUnknownAndFalsyValues: true },
    netItemCount: { label: 'netItemCount' }
};

const DimensionDataStore = React.createContext<State>(initialState);
const { Provider } = DimensionDataStore;

const DimensionDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, _] = React.useReducer((state = initialState) => state, initialState);

    return <Provider value={state}>{children}</Provider>;
};

export { DimensionDataProvider, DimensionDataStore };
