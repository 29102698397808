import React, { useEffect, useState } from 'react';
import { propOr } from 'ramda';
import { flatten } from 'lodash';
import { DynamicRoutesContext } from './DynamicRoutesContext';
import { SetDynamicRoutes, Route } from './routingTypes';
import { allRoutes, AllRoutes } from './allRoutes';
import { OnDone } from 'Authentication/bootstrap/Bootstrap';
import { settingsStore } from 'stores/settings';

export const DynamicRoutes = ({
    onDone,
    userSettingsDone
}: {
    onDone: OnDone;
    userSettingsDone: boolean;
}) => {
    const [hubspotEnabled, setHubspotEnabled] = useState<boolean>(false);
    const { availableService, availableDashboards, currentUserRoles, featureFlags } =
        React.useContext(settingsStore);
    const { setDynamicRoutes } = React.useContext(DynamicRoutesContext);

    // Add Hubspot Script if feature flag is enabled
    useEffect(() => {
        if (featureFlags.hubspotReports && !hubspotEnabled) {
            console.log('Hubspot Reporting: Enabled');
            const script = document.createElement('script');

            script.id = 'hs-script-loader';
            script.type = 'text/javascript';
            script.defer = true;
            script.async = true;
            script.src = 'https://js-eu1.hs-scripts.com/144173778.js';

            document.body.appendChild(script);

            setHubspotEnabled(true);
        }
    }, [featureFlags.hubspotReports]);

    React.useEffect(() => {
        if (userSettingsDone) {
            if (availableService && availableDashboards) {
                createRoutes(
                    setDynamicRoutes,
                    availableService,
                    availableDashboards,
                    currentUserRoles.reduce((p, v) => Math.max(p, rolesToLevel[v] || 0), 0)
                );
                setTimeout(() => {
                    onDone(true);
                });
            }
        }
    }, [availableService, availableDashboards, userSettingsDone]);

    return null;
};

const createRoutes = (
    setDynamicRoutes: SetDynamicRoutes,
    availableService: string[],
    availableDashboards: string[],
    roleLevel: number
) => {
    const availableRoutes = availableService.reduce((previous: Route<any>[], service) => {
        previous = previous.concat(
            propOr<Route<any>[], AllRoutes, Route<any>[]>([], service, allRoutes).filter(
                ({ label }) => {
                    if (!label) {
                        return true;
                    }
                    return availableDashboards.includes(label);
                }
            )
        );
        return previous;
    }, []);
    const defaultServices = ['as-core-services'];
    const services = availableService.concat(defaultServices);

    const routes = availableRoutes
        .concat(
            flatten(
                defaultServices.map((service) =>
                    propOr<Route<any>[], AllRoutes, Route<any>[]>([], service, allRoutes)
                )
            )
        )
        .filter((route) => {
            if (route.requiredRole) {
                if ((rolesToLevel[route.requiredRole] || 0) <= roleLevel) {
                    return true;
                }
                return false;
            }
            return true;
        });

    setDynamicRoutes(services, routes);
};

const rolesToLevel: { [key: string]: number } = {
    CUSTOMER: 0,
    USER: 1,
    MANAGER: 2,
    ADMINISTRATOR: 3
};
