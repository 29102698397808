import { TFunction } from 'i18next';
import { compose, sort, descend, path, toPairs, reduce, ifElse, prop, propOr } from 'ramda';
import Highcharts from 'highcharts';
import { Theme } from '@mui/material/styles';
import { ASCubeFilter, DimensionData, FilterValue } from './common/types';
import { Maybe } from './maybe';
import {
    CUSTOMERS_GROUPED_AGE,
    CUSTOMERS_KEY_FIGURES_AGE,
    EMAIL_CAMPAIGN_CUSTOMERS_KEY_FIGURES_AGE,
    BASE_TRANSACTIONS_ORDER_REF,
    TRANSACTIONS_ORDER_REF
} from './common/constants';
import { getChartColors } from 'components/charts';
import { filterValueCheck } from 'utils/filter/filterCheck';
import { missingLabelValuesHandler } from 'utils/missingLabelValuesHandler';
import { DimensionInfo, FullDimension } from 'stores/dimensionData';

import { formatChartLabels } from 'utils/chartLabelFormatters';
import { bucketValuesSorting } from './bucketValuesSorting';

//Column chart dimensions where we need to fill the gaps between labels
export const sortSpecialCharactersColumnDimensions = [CUSTOMERS_GROUPED_AGE];

export const customersAgeKeyFigureDimensions = [
    CUSTOMERS_KEY_FIGURES_AGE,
    EMAIL_CAMPAIGN_CUSTOMERS_KEY_FIGURES_AGE
];

export const mapFilterChartData =
    (
        type: string,
        t: TFunction,
        theme: Theme,
        filters: ASCubeFilter[],
        dimension: FullDimension,
        metric: string,
        operator: string,
        translatedDimension: string,
        dimensionInfo: DimensionInfo,
        totals?: { [key: string]: string }[],
        transformChartLabel?: (label: string | number, prop: TFunction) => string,
        color?: string,
        linearColors?: string[],
        focusedSegments?: boolean,
        visibleFocusedDimensions?: string[]
    ) =>
    (data: DimensionData[]): Highcharts.Options['series'] => {
        const filterValues = Maybe(filters.find((filter) => filter.dimension === dimension))
            .chain((filter) => Maybe(filter.values))
            .map((values) => values.map((value) => filterValueCheck(value)))
            .getOrElse([] as string[]);

        if (sortSpecialCharactersColumnDimensions.includes(dimension)) {
            data = missingLabelValuesHandler(data, '<16', '70+', dimension, metric);
        } else if (dimensionInfo.orderData) {
            data = dimensionInfo.orderData(data, dimensionInfo, dimension);
        } else if (type === 'linear') {
            data = data.sort((a, b) => Number(b[dimension]) - Number(a[dimension]));
        } else {
            data = data.sort((a, b) => Number(b[metric]) - Number(a[metric]));
        }

        if (type === 'column') {
            return [
                {
                    name: translatedDimension,
                    type: 'column',
                    data: data
                        .filter((e) => e[metric] !== '0')
                        .map((dataPoint) => ({
                            y: Number(dataPoint[metric]),
                            name: transformChartLabel
                                ? transformChartLabel(dataPoint[dimension], t)
                                : dataPoint[dimension],
                            color:
                                filterValues.length === 0 ||
                                filterValues.includes(String(dataPoint[dimension])) ===
                                    (operator === 'equals')
                                    ? color
                                    : color && `${color}80`,
                            custom: {
                                filterValue: dataPoint[dimension],
                                breadcrumbValue: transformChartLabel
                                    ? transformChartLabel(dataPoint[dimension], t)
                                    : dataPoint[dimension]
                            }
                        }))
                }
            ];
        }
        if (type === 'linear') {
            return [
                {
                    name: t(dimension),
                    type: 'bar',
                    data: data
                        .filter((e) => e[metric] !== '0')
                        .map((dataPoint) => ({
                            y: Number(dataPoint[metric]),
                            x: Number(dataPoint[dimension]),
                            color:
                                filterValues.length > 0
                                    ? linearChartRangeColors(
                                          filterValues,
                                          String(dataPoint[dimension]),
                                          operator,
                                          linearColors,
                                          theme
                                      )
                                    : linearColors?.[0] || theme.colors.filterGroupTransaction
                        }))
                }
            ];
        }
        return [
            {
                name: translatedDimension,
                type: 'bar',
                data: data
                    .filter((e) => e[metric] !== '0')
                    .map((dataPoint, index) => ({
                        y: Number(dataPoint[metric]),
                        x: index,
                        color:
                            filterValues.length === 0 ||
                            filterValues.includes(String(dataPoint[dimension])) ===
                                (operator === 'equals')
                                ? color
                                : color && `${color}80`,
                        category: formatChartLabels(dataPoint[dimension], t, transformChartLabel),
                        custom: {
                            filterValue: dataPoint[dimension],
                            breadcrumbValue: formatChartLabels(
                                dataPoint[dimension],
                                t,
                                transformChartLabel
                            ),
                            totals: totals?.length ? Number(totals[0][metric]) : undefined,
                            // Logic:
                            //    If we are not in focused mode, all dimensions will be visible.
                            //    Else (we are in focused mode):
                            //      If this dimension is one of the filters OR in 'visibleFocusedDimensions', show it - else hide it.
                            hide:
                                focusedSegments && filterValues.length > 0
                                    ? filterValues.includes(String(dataPoint[dimension])) ===
                                          (operator === 'equals') ||
                                      (visibleFocusedDimensions &&
                                          visibleFocusedDimensions?.includes(
                                              String(dataPoint[dimension])
                                          )) ===
                                          (operator === 'equals')
                                        ? false
                                        : true
                                    : false
                        }
                    }))
            }
        ];
    };

export const mapColumnChartData =
    (
        t: TFunction,
        theme: Theme,
        dimension: string,
        salesTime: string,
        label: string,
        metric: string,
        transformChartLabel?: (label: string | number, prop: TFunction) => string
    ) =>
    (data: DimensionData[]) =>
        compose(
            transformColumnSeries(t, theme, transformChartLabel),
            sort(descend(path(['1', 'total']))),
            toPairs,
            reduce(
                (previous, point: DimensionData) => {
                    previous[point[dimension]] = previous[point[dimension]] || {
                        data: [],
                        total: 0
                    };
                    const y = Number(point[metric]);
                    previous[point[dimension]].data.push({
                        x: Number(new Date(point[salesTime])),
                        y
                    });
                    previous[point[dimension]].total += y;
                    return previous;
                },
                {} as { [key: string]: { data: Array<{ x: number; y: number }>; total: number } }
            )
        )(data.filter((e) => e[metric] !== '0'));
const transformColumnSeries =
    (
        t: TFunction,
        theme: Theme,
        transformChartLabel?: (label: string | number, prop: TFunction) => string
    ) =>
    (series: [string, { data: Array<{ x: number; y: number }> }][]): Highcharts.Options['series'] =>
        series.map(([name, { data }], index) => ({
            type: 'column',
            name: String(formatChartLabels(name, t, transformChartLabel)),
            data,
            color: getChartColors(theme)[index % 50]
        }));

export const mapLineChartData =
    (
        t: TFunction,
        theme: Theme,
        dimension: string,
        dateDimension: string,
        metric: string,
        cumulative: boolean
    ) =>
    (data: DimensionData[]) =>
        compose(
            ifElse(
                () => cumulative,
                transformCumulativeLineSeries(t, theme),
                transformLineSeries(t, theme)
            ),
            toPairs,
            reduce(
                (previous, point: DimensionData) => {
                    previous[point[dimension]] = previous[point[dimension]] || {
                        data: [],
                        total: 0
                    };
                    const y = Number(point[metric]);
                    previous[point[dimension]].data.push({
                        x: Number(new Date(point[dateDimension])),
                        y
                    });
                    previous[point[dimension]].total += y;
                    return previous;
                },
                {} as { [key: string]: { data: Array<{ x: number; y: number }>; total: number } }
            )
        )(data);

const transformLineSeries =
    (t: TFunction, theme: Theme) =>
    (
        series: [string, { data: Array<{ x: number; y: number }>; total: number }][]
    ): Highcharts.Options['series'] =>
        series.map(([name, { data, total }], index) => ({
            type: 'line',
            name: t(name),
            data,
            color: getChartColors(theme)[index % 50],
            total: total / data.length
        }));

const transformCumulativeLineSeries =
    (t: TFunction, theme: Theme) =>
    (
        series: [string, { data: Array<{ x: number; y: number }>; total: number }][]
    ): Highcharts.Options['series'] =>
        series.map(([name, { data, total }], index) => {
            let cumulative = 0;
            return {
                type: 'line',
                name: t(name),
                data: data.map(({ x, y }) => ({
                    x,
                    y: (cumulative += y)
                })),
                color: getChartColors(theme)[index % 50],
                total: total / data.length
            };
        });
//Used when there is '+' in dimension value
export const toNumber = (str: string) => {
    if (Number(str)) {
        return Number(str);
    } else {
        return Number(str.substring(0, str.length - 1));
    }
};

export const mapBinaryFilterChartData = (
    t: TFunction,
    rawData: DimensionData[],
    filterValues: string[] | FilterValue[],
    dimension: FullDimension,
    metric: string,
    theme: Theme,
    dimensionInfo: DimensionInfo,
    transformChartLabel?: (label: string | number, prop: TFunction) => string
): Highcharts.Options['series'] => {
    if (dimension === BASE_TRANSACTIONS_ORDER_REF || dimension === TRANSACTIONS_ORDER_REF) {
        const groupedData: DimensionData[] = Object.values(
            rawData.reduce(function (r, { [metric]: itemCount }) {
                if (itemCount >= 7) {
                    itemCount = '7+';
                }
                r[itemCount] = r[itemCount] || { itemCount, numberOfOrders: 0 };
                r[itemCount].numberOfOrders++;
                return r;
            }, Object.create(null))
        );
        return groupedData.map((data, index) => {
            const seriesColors = getChartColors(theme);
            const colorIndex = index % seriesColors.length;
            const dataDimension = prop(dimension, data);
            return {
                type: 'bar',
                name: propOr('', 'itemCount', data),
                color:
                    filterValues.length === 0 || filterValues.includes(String(data[dimension]))
                        ? seriesColors[colorIndex]
                        : `${seriesColors[colorIndex]}80`,

                data: [
                    {
                        category: prop('itemCount', data),
                        y: Math.abs(Number(propOr(0, 'numberOfOrders', data))),
                        isNegative: Number(propOr(0, 'numberOfOrders', data)) < 0,
                        custom: {
                            filterValue: dataDimension,
                            breadcrumbValue: String(
                                formatChartLabels(dataDimension, t, transformChartLabel)
                            )
                        }
                    }
                ]
            };
        });
    } else if (customersAgeKeyFigureDimensions.includes(dimension)) {
        const order: string[] = ['<20', '20-29', '30-39', '40-49', '50-59', '60-69', '70+'];
        rawData = bucketValuesSorting(order, rawData, dimension, metric);
    }

    if (dimensionInfo.orderData) {
        dimensionInfo.orderData(rawData, dimensionInfo, dimension);
    }
    return rawData.map((data, index) => {
        const seriesColors = getChartColors(theme);
        const colorIndex = index % seriesColors.length;
        const dataDimension = prop(dimension, data);
        return {
            type: 'bar',
            name: propOr('', dimension, data),
            color:
                filterValues.length === 0 || filterValues.includes(String(data[dimension]))
                    ? seriesColors[colorIndex]
                    : `${seriesColors[colorIndex]}80`,

            data: [
                {
                    category: String(formatChartLabels(dataDimension, t, transformChartLabel)),
                    y: Math.abs(Number(propOr(0, metric, data))),
                    isNegative: Number(propOr(0, metric, data)) < 0,
                    custom: {
                        filterValue: dataDimension,
                        breadcrumbValue: String(
                            formatChartLabels(dataDimension, t, transformChartLabel)
                        )
                    }
                }
            ]
        };
    });
};

const linearChartRangeColors = (
    filterValues: string[],
    value: string,
    operator: string,
    linearColors: string[] | undefined,
    theme: Theme
) => {
    if (operator === 'equals') {
        if (Number(value) >= Number(filterValues[0]) && Number(value) <= Number(filterValues[1])) {
            return linearColors?.[1] || theme.colors.filterGroupProduct;
        } else {
            return linearColors?.[0] || theme.colors.filterGroupTransaction;
        }
    } else {
        if (Number(value) >= Number(filterValues[0]) && Number(value) <= Number(filterValues[1])) {
            return linearColors?.[0] || theme.colors.filterGroupTransaction;
        } else {
            return linearColors?.[1] || theme.colors.filterGroupProduct;
        }
    }
};
