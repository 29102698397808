/* eslint-disable arrow-body-style */
import React from 'react';
import { initFeature } from './inlinemanual';
import { settingsStore } from 'stores/settings';
import { Tracking } from 'externals/tracking';

export default function Externals({
    onDone,
    userSettingsDone
}: {
    onDone: React.Dispatch<React.SetStateAction<boolean>>;
    userSettingsDone: boolean;
}) {
    const { featureFlags, currentUserRoles, currentUser, tenant, mixpanelSettings, env, language } =
        React.useContext(settingsStore);
    const [inlineManualDone, setInlineManualDone] = React.useState<boolean>(false);
    const [mixpanelDone, setMixpanelDone] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (userSettingsDone && language) {
            if (featureFlags.inlineManual) {
                console.log(`language in Externals: ${language}`);

                initFeature({
                    currentUser,
                    currentUserRoles,
                    tenant,
                    onDone: setInlineManualDone,
                    language
                });
            } else {
                setInlineManualDone(true);
            }
            console.log('Initializing tracking');
            if (env === 'PROD') {
                Tracking.initMixpanel('fbc0f6c01e39ed4f4ce59af9cac731d5', 'ActivityStream 2.0');
                const { APIKey = null, projectName = null } = mixpanelSettings || {};
                if (APIKey && projectName) {
                    Tracking.initMixpanel(APIKey, projectName);
                }
            }
            //Developer can should uncomment this for development purposes and remember to return it after the dev process is done
            else if (env === 'QA' /*|| env === 'DEV'*/) {
                Tracking.initMixpanel(
                    '2f97e7e2ff7d74b654aa6246fdf0ad4b',
                    'ActivityStream 2.0 DEV/QA'
                );
            }
            if (currentUser) {
                Tracking.setUserID(currentUser.email);
                Tracking.userParams({
                    $email: currentUser.email,
                    $name: currentUser.name
                });
            }
            // Set tracking parameters for the environment and tenant
            Tracking.params({
                environment: env,
                client_revision: process.env.APP_VERSION,
                tenant: tenant
            });

            Tracking.flushQueue();
            setMixpanelDone(true);
        }
    }, [userSettingsDone]);

    React.useEffect(() => {
        if (inlineManualDone && mixpanelDone) {
            onDone(true);
        }
    }, [inlineManualDone, mixpanelDone]);

    return <div />;
}
