import React from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

type ASSwitchProps = {
    checked: boolean;
    disabled?: boolean;
    onChange: (e?: React.ChangeEvent<HTMLInputElement>) => void;
    backgroundColor?: string;
    disabledBackgroundColor?: string;
    thumbText?: string;
    thumbTextColor?: string;
    title?: string;
    customStyles?: any;
    children?: React.ReactNode;
};

const ASSwitchButton: React.FC<ASSwitchProps> = ({
    onChange,
    checked,
    disabled,
    backgroundColor,
    disabledBackgroundColor,
    thumbText,
    thumbTextColor,
    children,
    title
}) => {
    return (
        <Wrapper title={title}>
            <StyledSwitch
                disabled={disabled}
                checked={checked}
                onChange={onChange}
                backgroundColor={backgroundColor}
                disabledBackgroundColor={disabledBackgroundColor}
                thumbText={thumbText}
                thumbTextColor={thumbTextColor}
            />
            <Label>{children}</Label>
        </Wrapper>
    );
};

const Label = styled('span')`
    display: flex;
    margin-left: 6px;
    font-size: ${({ theme }) => theme.fontSizes.small};
`;

const Wrapper = styled('div')`
    display: flex;
    align-items: center;
`;

const StyledSwitch = styled(Switch)<{
    thumbText?: string;
    disabledBackgroundColor?: string;
    backgroundColor?: string;
    thumbTextColor?: string;
}>`
    width: 28px;
    height: 16px;
    padding: 0;
    display: flex;

    .MuiSwitch-switchBase {
        color: ${({ theme }) => theme.colors.background};

        & + .MuiSwitch-track {
            opacity: 1;
            background-color: ${({ disabledBackgroundColor }) =>
                disabledBackgroundColor ? disabledBackgroundColor : ''};
            border-color: ${({ disabledBackgroundColor }) =>
                disabledBackgroundColor ? disabledBackgroundColor : ''};
        }
    }

    .Mui-checked {
        transform: translateX(12px);
        color: white;
        & + .MuiSwitch-track {
            opacity: 1;
            background-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
            border-color: ${({ backgroundColor }) => (backgroundColor ? backgroundColor : '')};
        }
    }

    .MuiSwitch-thumb {
        width: 14px;
        height: 14px;
        box-shadow: none;
        background-color: #fff;
        position: absolute;
        top: 1px;
        left: 1px;

        &:before {
            content: '${({ thumbText }) => (thumbText ? thumbText : '')}';
            color: ${({ thumbTextColor }) => (thumbTextColor ? thumbTextColor : '')};
            position: absolute;
            font-size: 10px;
            line-height: 14px;
            top: 0px;
            left: 3px;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .MuiSwitch-track {
        border: 1px solid ${({ theme }) => theme.colors.dividers};
        border-radius: 16 / 2;
        opacity: 1;
        background-color: ${({ theme }) => theme.colors.dividers};
    }
`;

export { ASSwitchButton };
