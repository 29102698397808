import { TFunction } from 'i18next';
import { Theme } from '@mui/material/styles';
import { Aspect } from '../../common/tile/TileAspect';
import { InventoryData } from 'utils/dataloaders/inventoryLoader';

export const mapTicketStatusData = (inventory: InventoryData, theme: Theme, t: TFunction) => {
    const inventorySorted = (
        Object.keys(inventory?.price_categories).every((priceCategory) =>
            isNaN(Number(priceCategory))
        )
            ? Object.entries(inventory?.price_categories || {}).sort(([a], [b]) =>
                  a.localeCompare(b)
              )
            : Object.entries(inventory?.price_categories).sort(([a], [b]) => Number(a) - Number(b))
    ).reduce(
        (p, [key, value]) => {
            p[key] = value;
            return p;
        },
        {} as InventoryData['price_categories']
    );

    const inventoryWithSortedCategories = Object.keys(inventorySorted);

    const chartCategories = [t('total')].concat(
        inventoryWithSortedCategories.map((category) => {
            if (!category) {
                return t('unknown');
            }
            return category;
        })
    );

    const mappedChartData = possibleTicketStatuses
        .map((status) => ({
            type: 'bar',
            name: t(status),
            // @ts-ignore
            color: theme.colors[`${status}ChartBar`],
            data: [inventory[status] || 0].concat(
                Object.values(inventorySorted).map((category) => category[status] || 0)
            )
        }))
        .filter((serie) => !serie.data.every((x) => x === 0));

    return [mappedChartData, chartCategories];
};

export const mapInventoryAspects = (
    inventory: InventoryData,
    theme: Theme,
    t: TFunction
): Aspect[] => {
    const ticketStatuses = possibleTicketStatuses.filter((status) =>
        inventory.hasOwnProperty(status)
    );

    const mappedInventoryAspects = ticketStatuses
        .reverse()
        .map((status) => ({
            label: t(status),
            value: inventory[status]
        }))
        .filter((aspect) => aspect.value && aspect.value > 0);
    const capacityAspect = {
        label: t('capacity'),
        value: inventory?.items_for_sale || 0
    };

    return mappedInventoryAspects.concat(capacityAspect) as Aspect[];
};

const possibleTicketStatuses: ['oversoldCount', 'open', 'killed', 'held', 'reserved', 'sold'] = [
    'oversoldCount',
    'open',
    'killed',
    'held',
    'reserved',
    'sold'
];

export const mapCampaignStatusData = (inventory: Aspect[], theme: Theme, t: TFunction) => {
    const chartCategories = [t('total')];

    const newInventory: { [key: string]: string | number | JSX.Element } = {};
    for (let i = 0; i < inventory.length; i++) {
        newInventory[inventory[i].label] = inventory[i].value;
    }

    const campaignStatuses = possibleCapmaignStatuses.filter((status) =>
        newInventory.hasOwnProperty(status)
    );

    const mappedChartData = campaignStatuses
        .map((status: string) => ({
            type: 'bar',
            name: t(status),
            // @ts-ignore
            color: theme.colors[`${status}ChartBar`],
            data: [newInventory[status]]
        }))
        .reverse();

    return [mappedChartData, chartCategories];
};

const possibleCapmaignStatuses: ['Delivered', 'Bounced'] = ['Delivered', 'Bounced'];
