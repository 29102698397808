import React from 'react';
import { compose, pathOr } from 'ramda';
import { DateRange } from '../../common/datepicker/types';
import { StatsTable } from './StatsTable';
import { getEventListQuery } from './queries';
import { SortDirection, EventListData, TransformedEventListData } from './types';
import { ASCubeFilter } from 'utils/common/types';
import { cubejsApi } from 'utils/api/CubeAPI';
import {
    DESC,
    TRANSACTIONS_PRODUCT_REF,
    CUSTOMERS_DISTINCT_COUNT,
    CUSTOMERS_IS_FIRST_TIME_BUYER,
    TRANSACTIONS_AVERAGE_TICKET_PRICE,
    TRANSACTIONS_REVENUE,
    TRANSACTIONS_ITEMCOUNT,
    TRANSACTIONS_PRODUCT_LABEL,
    TRANSACTIONS_PRODUCT_TIME_BEGINS,
    PRODUCTS_INVENTORY_HOLDS,
    PRODUCTS_INVENTORY_CAPACITY,
    PRODUCTS_INVENTORY_KILLS,
    TRANSACTIONS_CURRENCY,
    TRANSACTIONS_VENUE_REF,
    TRANSACTIONS_VENUE_LABEL
} from 'utils/common/constants';

import { tableDimensions, tableMeasures } from 'views/dashboards/EventStatusOverview/utils';
import { settingsStore } from 'stores/settings';

type StatsTableWrapperProps = {
    filters: ASCubeFilter[];
    baseFilters: ASCubeFilter[];
    metric: string;
    timePeriod: DateRange;
    salesTime: string;
    topHeaders?: JSX.Element;
    headersHandler: any;
    tableDimensions: string[];
    tableMeasures: string[];
    styledTable: any;
    tableLegends: boolean;
};

const StatsTableWrapper = ({
    filters,
    baseFilters,
    metric,
    timePeriod,
    salesTime,
    topHeaders,
    headersHandler,
    styledTable,
    tableLegends
}: StatsTableWrapperProps) => {
    const { tenantTimezone } = React.useContext(settingsStore);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [sortDirection] = React.useState<SortDirection>(DESC);
    const [sortBy] = React.useState<string>(metric);
    const [rawData, setRawData] = React.useState<EventListData[]>([]);

    const transformRawData = (data: EventListData[]): EventListData[] => {
        const transformedData = data.reduce(
            (previous: TransformedEventListData, value: EventListData) => {
                const product = value[TRANSACTIONS_PRODUCT_REF];
                if (!previous[product]) {
                    previous[product] = {
                        [TRANSACTIONS_PRODUCT_REF]: product,
                        [TRANSACTIONS_PRODUCT_LABEL]: value[TRANSACTIONS_PRODUCT_LABEL],
                        [TRANSACTIONS_PRODUCT_TIME_BEGINS]: value[TRANSACTIONS_PRODUCT_TIME_BEGINS],
                        [PRODUCTS_INVENTORY_HOLDS]: value[PRODUCTS_INVENTORY_HOLDS],
                        [PRODUCTS_INVENTORY_CAPACITY]: value[PRODUCTS_INVENTORY_CAPACITY],
                        [PRODUCTS_INVENTORY_KILLS]: value[PRODUCTS_INVENTORY_KILLS],
                        [CUSTOMERS_IS_FIRST_TIME_BUYER]: value[CUSTOMERS_IS_FIRST_TIME_BUYER],
                        [TRANSACTIONS_CURRENCY]: value[TRANSACTIONS_CURRENCY],
                        [TRANSACTIONS_VENUE_REF]: value[TRANSACTIONS_VENUE_REF],
                        [TRANSACTIONS_VENUE_LABEL]: value[TRANSACTIONS_VENUE_LABEL],
                        totalCustomers: 0,
                        firstTimeCustomers: 0,
                        [TRANSACTIONS_ITEMCOUNT]: 0,
                        [TRANSACTIONS_REVENUE]: 0
                    };
                }
                if (String(value[CUSTOMERS_IS_FIRST_TIME_BUYER]) === '1') {
                    previous[product].firstTimeCustomers += Number(value[CUSTOMERS_DISTINCT_COUNT]);
                }
                previous[product].totalCustomers += Number(value[CUSTOMERS_DISTINCT_COUNT]);
                previous[product][TRANSACTIONS_REVENUE] += Number(value[TRANSACTIONS_REVENUE]);
                previous[product][TRANSACTIONS_ITEMCOUNT] += Number(value[TRANSACTIONS_ITEMCOUNT]);
                return previous;
            },
            {} as TransformedEventListData
        );

        return Object.values(transformedData).map((value) => {
            value.firstTimeCustomerPercent = value.firstTimeCustomers / value.totalCustomers;
            value[TRANSACTIONS_AVERAGE_TICKET_PRICE] =
                value[TRANSACTIONS_ITEMCOUNT] === 0
                    ? 0
                    : value[TRANSACTIONS_REVENUE] / value[TRANSACTIONS_ITEMCOUNT];
            return value;
        });
    };

    React.useEffect(() => {
        setLoading(true);
        cubejsApi
            .load(
                getEventListQuery(
                    salesTime,
                    timePeriod,
                    filters,
                    baseFilters,
                    sortBy,
                    sortDirection,
                    tableDimensions,
                    tableMeasures,
                    tenantTimezone
                )
            )
            .then(
                compose(
                    setRawData,
                    transformRawData,
                    pathOr<EventListData[]>([], ['loadResponse', 'results', '0', 'data'])
                )
            )
            .finally(() => {
                setLoading(false);
            });
    }, [salesTime, timePeriod, filters, sortBy, sortDirection, baseFilters]);

    return (
        <StatsTable
            events={rawData}
            loading={loading}
            topHeaders={topHeaders}
            headersHandler={headersHandler}
            styledTable={styledTable}
            tableLegends={tableLegends}
        />
    );
};

export { StatsTableWrapper };
